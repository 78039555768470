import * as yup from 'yup';

import { ElementCompositionRequest, ElementConfigurationSettingRequest } from './models/model';

export const SPECIFIC_RAW_MATERIALS = '特定原材料';
export const ITEMS_EQUIVALENT_TO_SPECIFIC_RAW_MATERIALS = '特定原材料に準ずる品目';

export const NEW = '新規';
export const PENDING_CONFIRMATION = '確認待ち';
export const CONFIRMED = '確認済';
export const PENDING_APPROVAL = '承認待ち';
export const APPROVED = '承認済み';
export const TEMPORARY_SAVE = '一時保存';
export const SYSTEM_ID = '1035';

export enum AuthorityLevels {
  ADMIN = 999,
  APPROVAL = 200,
  NORMAL = 100,
}

export type FieldError = {
  key: string;
  errors: string[];
};

export type CustomError = {
  key: number | string;
  errors: FieldError[];
};

export const validateElementCompositionsError = (
  elementCompositions: Partial<ElementCompositionRequest>[],
  schema: yup.AnyObjectSchema
) => {
  const custom_errors: CustomError[] = []; // declare custom_errors outside try-catch
  elementCompositions.forEach((composition, index) => {
    try {
      schema.validateSync(composition, {
        abortEarly: false,
      });
    } catch (error: yup.ValidationError | any) {
      custom_errors.push({
        // push error to custom_errors array
        key: composition.composition_cd || index,
        errors: error.inner.map((err: { path: string; message: string }) => ({
          key: err.path,
          errors: [err.message],
        })),
      });
    }
  });

  return custom_errors; // return custom_errors outside try-catch
};

export const validationElementError = (
  element: Partial<ElementConfigurationSettingRequest>,
  schema: yup.AnyObjectSchema
) => {
  const field_errors = Array<FieldError>();
  try {
    schema.validateSync(element, {
      abortEarly: false,
    });
  } catch (error: yup.ValidationError | any) {
    field_errors.push(
      ...error.inner.map((err: { path: string; message: string }) => ({
        key: err.path,
        errors: [err.message],
      }))
    );
  }
  return field_errors;
};
