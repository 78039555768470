import { Button, Col, Form, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import api from '../Service';
import '../style.scss';
import {
  ElementCompositionResponse,
  ElementConfigurationSettingList,
  ElementConfigurationSettingRequest,
  ElementConfigurationSettingResponse,
} from '../models/model';
import {
  PENDING_CONFIRMATION,
  PENDING_APPROVAL,
  FieldError,
  CustomError,
  validationElementError,
  validateElementCompositionsError,
} from '../constant';
import GroupButton, { HandleButtonClickParam } from '../components/ButtonApproveGroup';
import CheckBoxBusinessFormatItem from '../components/CheckBoxBusinessFormatMaster';
import ClassificationItemSelect from '../components/SelectClassificationMaster';
import DatePickerCustom from '../components/DatePickerCustom';
import SearchCompositionModal from 'features/SearchCompositionModal';
import { ApiError, ApiResponse } from 'models';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import ViewDetailCompositionModal from 'features/ViewDetailCompositionModal';
import * as yup from 'yup';
import { dateToStringFormat } from 'utils/date-time-utils';
import { useDispatch } from 'react-redux';
import { partsConfigurationActions } from '../Slice';

const COLUMN_WIDTH = [1.875, 6.25, 22.5, 18.75, 9.375, 9.375, 9.375, 5];

function PartsConfigurationDetailSetting() {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { element_cd }: { element_cd: string } = useParams();

  //type
  type RouteAction = 'copy' | 'edit' | 'new';

  //validation schema

  const elementSchema = yup.object().shape({
    element_name: yup.string().required(t('PartsConfigurationDetail.validation.required')),
    business_format_cd: yup.string().required(t('PartsConfigurationDetail.validation.required')),
    element_classification_cd: yup.string().required(t('PartsConfigurationDetail.validation.required')),
    supplementary_unit_conversion_rate_1: yup
      .number()
      .positive(t('PartsConfigurationDetail.validation.positive'))
      .min(0, t('PartsConfigurationDetail.validation.min'))
      .required(t('PartsConfigurationDetail.validation.required'))
      .typeError(t('PartsConfigurationDetail.validation.number')),
    supplementary_unit_conversion_rate_2: yup
      .number()
      .positive(t('PartsConfigurationDetail.validation.positive'))
      .min(0, t('PartsConfigurationDetail.validation.min'))
      .required(t('PartsConfigurationDetail.validation.required'))
      .typeError(t('PartsConfigurationDetail.validation.number')),
  });

  const elementCompositionSchema = yup.object().shape({
    composition_cd: yup.string().required(t('PartsConfigurationDetail.validation.required')),
    sort_key: yup
      .number()
      .positive(t('PartsConfigurationDetail.validation.positive'))
      .integer(t('PartsConfigurationDetail.validation.number'))
      .required(t('PartsConfigurationDetail.validation.required'))
      .typeError(t('PartsConfigurationDetail.validation.number')),
    effective_start_date: yup.string().required(t('PartsConfigurationDetail.validation.required')),
    effective_end_date: yup.string().required(t('PartsConfigurationDetail.validation.required')),
    composition_quantity: yup
      .number()
      .positive(t('PartsConfigurationDetail.validation.positive'))
      .min(0, t('PartsConfigurationDetail.validation.min'))
      .required(t('PartsConfigurationDetail.validation.required'))
      .typeError(t('PartsConfigurationDetail.validation.number')),
  });

  //ref
  const actionRef = useRef<RouteAction>('new');
  const elementClassificationRef = useRef<string>();
  const businessFormatRef = useRef<string>();
  const selectedCompositionsRef = useRef<string[]>([]);
  const currentCompositionsRef = useRef<string>('');

  // constant
  const apiCalls = {
    [HandleButtonClickParam.CONFIRMATION_REQUEST]: api.confirmRequest,
    [HandleButtonClickParam.APPROVAL_REQUEST]: api.approveRequest,
    [HandleButtonClickParam.ADMIT]: api.admitRequest,
    [HandleButtonClickParam.REMAND]: api.cancelRequest,
    [HandleButtonClickParam.TEMPORARY_SAVE]: api.temporarySaveRequest,
  };

  //state
  const [data, setData] = useState<ElementConfigurationSettingResponse>();
  const [isOpenAddCompositionModal, setIsOpenAddCompositionModal] = useState<boolean>(false);
  const [isOpenDetailCompositionModal, setIsOpenDetailCompositionModal] = useState<boolean>(false);

  const [status, setStatus] = useState('');
  const [lastUpdater, setLastUpdater] = useState('');

  const [suspendDate, setSuspendDate] = useState(new Date());
  const [applicationDate, setApplicationDate] = useState<Date | null>(new Date());
  const [elementValidationErrors, setElementValidationErrors] = useState<Array<FieldError>>([]);
  const [compositionValidationErrors, setCompositionValidationErrors] = useState<Array<CustomError>>([]);

  if (location.pathname.includes('copy')) {
    actionRef.current = 'copy';
  } else if (location.pathname.includes('edit')) {
    actionRef.current = 'edit';
  } else if (location.pathname.includes('new')) {
    actionRef.current = 'new';
  }

  //useEffect
  useEffect(() => {
    fetchData();
    return () => {
      setData(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (data) validateElement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.business_format_cd,
    data?.element_classification_cd,
    data?.element_name,
    data?.supplementary_unit_conversion_rate_1,
    data?.supplementary_unit_conversion_rate_2,
  ]);

  useLayoutEffect(() => {
    if (data && data.element_compositions.length > 0) validateElementComposition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.element_compositions || data?.element_compositions.length]);

  //functions
  const fetchData = async () => {
    try {
      let id = element_cd;
      if (actionRef.current === 'new') {
        id = '0000000000';
      }
      const response = await api.getById(id, actionRef.current, '');
      businessFormatRef.current = response.business_format_cd;
      elementClassificationRef.current = response.element_classification_cd;
      setStatus(response.status);
      if (response.status === PENDING_CONFIRMATION) {
        setLastUpdater(response.applicant_cd);
      } else if (response.status === PENDING_APPROVAL) {
        setLastUpdater(response.verifier_cd);
      }

      if (response.suspend_date) {
        setSuspendDate(new Date(response.suspend_date));
      }
      setData(response);
    } catch (error) {
      console.error('Error fetching data: ', error);
      const res = (error as AxiosError<ApiError>)?.response?.data;
      const status = (error as AxiosError<ApiError>)?.response?.status;
      if (status === 400 && res?.message) {
        toast.error(res.message);
      }
      if (status === 404) {
        history.push('/404');
      }
    }
  };

  const requestUpdateStatus = async (
    data: ElementConfigurationSettingRequest,
    callback: (data: ElementConfigurationSettingRequest) => Promise<ApiResponse<ElementConfigurationSettingList>>
  ) => {
    try {
      const response = await callback(data);
      if (response) {
        toast.success(response.message);
        dispatch(partsConfigurationActions.updatePartsConfiguration(response.data));
        fetchData();
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
      const res = (error as AxiosError<ApiError>)?.response?.data;
      const status = (error as AxiosError<ApiError>)?.response?.status;
      if (status === 400 && res?.message) {
        toast.error(res.message);
      }
    }
  };

  // const approveRequestStatus = async (
  //   data: Partial<ElementConfigurationSettingRequest>,
  //   callback: (data: Partial<ElementConfigurationSettingRequest>) => Promise<ApiResponse>
  // ) => {
  //   try {
  //     const response = await callback(data);
  //     if (response) {
  //       toast.success(response.message);
  //       fetchData();
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data: ', error);
  //     const res = (error as AxiosError<ApiError>)?.response?.data;
  //     const status = (error as AxiosError<ApiError>)?.response?.status;
  //     if (status === 400 && res?.message) {
  //       toast.error(res.message);
  //     }
  //   }
  // };

  const validateElement = () => {
    let errors = new Array<FieldError>();
    if (data) {
      errors = validationElementError(data as Partial<ElementConfigurationSettingRequest>, elementSchema);
    }
    setElementValidationErrors(errors);
    // return errors;
  };

  const validateElementComposition = (validate_data?: ElementConfigurationSettingResponse) => {
    const compositions = validate_data?.element_compositions || data?.element_compositions;
    const compositionErrors = compositions
      ? validateElementCompositionsError(compositions, elementCompositionSchema)
      : [];
    setCompositionValidationErrors(compositionErrors);
  };

  // handlers
  const handleApproveButtonClick = (buttonType: HandleButtonClickParam) => {
    handleResetFilter();
    if (!data) return;
    if (elementValidationErrors.length > 0 || compositionValidationErrors.length > 0) {
      return;
    }
    const apiCall = apiCalls[buttonType];
    if (
      buttonType === HandleButtonClickParam.CONFIRMATION_REQUEST ||
      buttonType === HandleButtonClickParam.TEMPORARY_SAVE
    ) {
      const requestData = {
        element_cd: data.element_cd,
        element_name: data.element_name,
        business_format_cd: data.business_format_cd,
        remarks: data.remarks,
        element_classification_cd: data.element_classification_cd,
        suspend_date: data.suspend_date,
        element_unit_master: data.element_unit_master,
        supplementary_unit_1: data.supplementary_unit_1,
        supplementary_unit_2: data.supplementary_unit_2,
        supplementary_unit_conversion_rate_1: data.supplementary_unit_conversion_rate_1,
        supplementary_unit_conversion_rate_2: data.supplementary_unit_conversion_rate_2,
        change_flg:
          data.change_flg ||
          data.element_compositions.some((composition) => composition.change_flg) ||
          data.element_compositions.some((composition) => composition.is_add_new) ||
          data.element_compositions.some((composition) => composition.is_deleted && !composition.is_add_new),
        element_compositions: data.element_compositions.map((composition) => {
          return {
            id: composition.id,
            composition_cd: composition.composition_cd,
            change_flg: composition.change_flg,
            composition_quantity: composition.composition_quantity,
            sort_key: composition.sort_key,
            effective_start_date: composition.effective_start_date,
            effective_end_date: composition.effective_end_date,
            composition_unit_cd: composition.composition_unit_cd,
            is_deleted: composition.is_deleted || false,
          };
        }),
      } as ElementConfigurationSettingRequest;
      requestUpdateStatus(requestData, async () => await apiCall(requestData));
    } else {
      const approveRequestObj = {
        element_cd: data.element_cd as string,
      } as ElementConfigurationSettingRequest;
      requestUpdateStatus(approveRequestObj, async () => await apiCall(approveRequestObj));
    }
  };

  const handleInputCheckboxBusinessFormat = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    businessFormatRef.current = value;
    const updatedData = data ? { ...data, [name]: value } : { [name]: value };
    updatedData.change_flg = actionRef.current === 'edit' ? true : false;
    setData(updatedData as ElementConfigurationSettingResponse);
  };

  const handleInputElementClassification = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    elementClassificationRef.current = value;
    const updatedData = data ? { ...data, [name]: value } : { [name]: value };
    updatedData.change_flg = actionRef.current === 'edit' ? true : false;
    setData(updatedData as ElementConfigurationSettingResponse);
  };

  const handleChangeElementProperty = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    const updatedData = data ? { ...data, [name]: value } : { [value]: value };
    updatedData.change_flg = actionRef.current === 'edit' ? true : updatedData.change_flg;
    setData(updatedData as ElementConfigurationSettingResponse);
  };

  const handleOnChangeUsageUnit = (val: string, elm_composition_cd: string) => {
    const elm = data?.element_compositions.find((e) => e.composition_cd === elm_composition_cd);
    if (elm) {
      elm.composition_unit_cd = val;
      elm.change_flg = elm.change_flg || !elm.is_add_new;
      setData(data && { ...data, element_compositions: [...data.element_compositions] });
    }
  };

  const handleChangeElementCompositionQuantity = (val: string, elm_composition_cd: string) => {
    const composition = data?.element_compositions.find((e) => e.composition_cd === elm_composition_cd);
    if (composition) {
      composition.composition_quantity = isNaN(parseFloat(val)) ? undefined : parseFloat(val);
      composition.change_flg = composition.change_flg || !composition.is_add_new;
      const updatedData = data && { ...data, element_compositions: [...data.element_compositions] };
      setData(updatedData);
      validateElementComposition(updatedData);
    }
  };

  const handleChangeElementCompositionSortKey = (val: string, elm_composition_cd: string) => {
    const composition = data?.element_compositions.find((e) => e.composition_cd === elm_composition_cd);
    if (composition) {
      composition.sort_key = isNaN(parseInt(val)) ? undefined : parseInt(val);
      composition.change_flg = composition.change_flg || !composition.is_add_new;
      const updatedData = data && { ...data, element_compositions: [...data.element_compositions] };
      setData(updatedData);
      validateElementComposition(updatedData);
    }
  };

  const handleChangeComposEffectiveStartDate = (val: Date, elm_composition_cd: string) => {
    const composition = data?.element_compositions.find((e) => e.composition_cd === elm_composition_cd);
    if (composition) {
      composition.effective_start_date = dateToStringFormat(val, 'YYYY-MM-DD');
      composition.change_flg = composition.change_flg || !composition.is_add_new;
      setData(data && { ...data, element_compositions: [...data.element_compositions] });
    }
  };

  const handleChangeComposEffectiveEndDate = (val: Date, elm_composition_cd: string) => {
    const composition = data?.element_compositions.find((e) => e.composition_cd === elm_composition_cd);
    if (composition) {
      composition.effective_end_date = dateToStringFormat(val, 'YYYY-MM-DD');
      composition.change_flg = composition.change_flg || !composition.is_add_new;
      setData(data && { ...data, element_compositions: [...data.element_compositions] });
    }
  };

  //todo
  const handleAddComposition = (composition: any) => {
    if (composition !== undefined) {
      const existingCompositionIndex = data?.element_compositions.findIndex(
        (e) =>
          e.composition_cd === (composition.purchase_item_cd ?? composition.process_item_cd ?? composition.element_cd)
      );

      let updatedCompositions = [...(data?.element_compositions ?? [])];

      if (existingCompositionIndex !== undefined && existingCompositionIndex !== -1) {
        updatedCompositions[existingCompositionIndex].is_deleted = false;
      } else {
        const purchase_unit =
          'purchase_item_cd' in composition
            ? data?.purchase_units?.filter(
                (e) =>
                  e.unit_cd === composition.purchase_item_unit_cd ||
                  e.unit_cd === composition.purchase_supplementary_unit_cd_1 ||
                  e.unit_cd === composition.purchase_supplementary_unit_cd_2 ||
                  e.unit_cd === composition.purchase_supplementary_unit_cd_3
              )[0].unit_cd
            : '';

        const process_unit =
          'process_item_cd' in composition
            ? data?.purchase_units?.filter(
                (e) =>
                  e.unit_cd === composition.process_item_unit_cd ||
                  e.unit_cd === composition.process_supplementary_unit_cd_1 ||
                  e.unit_cd === composition.process_supplementary_unit_cd_2 ||
                  e.unit_cd === composition.process_supplementary_unit_cd_3
              )[0].unit_cd
            : '';

        const element_unit_cd =
          'element_cd' in composition
            ? data?.element_units?.filter(
                (e) =>
                  e.unit_cd === composition.element_unit_master ||
                  e.unit_cd === composition.element_supplementary_unit_1 ||
                  e.unit_cd === composition.element_supplementary_unit_2
              )[0].unit_cd
            : '';

        const compos = {
          id: 0,
          composition_cd: composition.purchase_item_cd ?? composition.process_item_cd ?? composition.element_cd,
          composition_unit_cd: element_unit_cd || process_unit || purchase_unit || '',
          sort_key: composition.sort_key ?? '',
          effective_start_date: composition.effective_start_date ?? '',
          effective_end_date: composition.effective_end_date ?? '',
          composition_quantity: 0,
          change_flg: composition.change_flag ?? false,
          purchase_item_name: composition.purchase_item_name ?? '',
          process_item_name: composition.process_item_name ?? '',
          supplier_item_cd: composition.supplier_item_cd ?? '',
          element_assoc_name: composition.element_name ?? '',
          purchase_item_unit_cd: composition.purchase_item_unit_cd ?? '',
          purchase_item_sub_unit_cd_1: composition.purchase_supplementary_unit_cd_1 ?? '',
          purchase_item_sub_unit_cd_2: composition.purchase_supplementary_unit_cd_2 ?? '',
          purchase_item_sub_unit_cd_3: composition.purchase_supplementary_unit_cd_3 ?? '',
          process_item_unit_cd: composition.process_item_unit_cd ?? '',
          process_item_sub_unit_cd_1: composition.process_supplementary_unit_cd_1 ?? '',
          process_item_sub_unit_cd_2: composition.process_supplementary_unit_cd_2 ?? '',
          process_item_sub_unit_cd_3: composition.process_supplementary_unit_cd_3 ?? '',
          element_unit_master_assoc: composition.element_unit_master ?? '',
          supplementary_unit_1_assoc: composition.element_supplementary_unit_1 ?? '',
          supplementary_unit_2_assoc: composition.element_supplementary_unit_2 ?? '',
          is_add_new: true,
          is_deleted: false,
        } as ElementCompositionResponse;

        updatedCompositions.push(compos);
      }

      const updatedData = data && { ...data, element_compositions: updatedCompositions };
      setData(updatedData);
      // validateElementComposition(updatedData);
    }
  };

  const handleChangeSuspendDate = (date: Date) => {
    setSuspendDate(date);
    if (data) {
      setData(
        data && {
          ...data,
          suspend_date: dateToStringFormat(date, 'YYYY-MM-DD'),
          change_flg: actionRef.current === 'edit' ? true : false,
        }
      );
    }
  };

  const handleFilterApplicableDate = (date: Date | null) => {
    setData(
      data && {
        ...data,
        element_compositions: data.element_compositions.map((e) => ({
          ...e,
          is_hidden: !(
            !date ||
            ((!e.effective_end_date ||
              (e.effective_end_date && e.effective_start_date <= dateToStringFormat(date, 'YYYY-MM-DD'))) &&
              (!e.effective_start_date ||
                (e.effective_end_date && e.effective_end_date >= dateToStringFormat(date, 'YYYY-MM-DD'))))
          ),
        })),
      }
    );
  };

  const handleResetFilter = () => {
    setApplicationDate(new Date());
    handleFilterApplicableDate(null);
  };

  const handleDeleteElementComposition = (composition_cd: string) => {
    let compositions = [...(data?.element_compositions ?? [])];
    if (actionRef.current === 'new' || actionRef.current === 'copy') {
      const index = compositions?.findIndex((c) => c.composition_cd === composition_cd);
      if (index !== undefined && index !== -1) {
        compositions?.splice(index, 1);
        setData(data && { ...data, element_compositions: compositions ?? [] });
      }
    } else if (actionRef.current === 'edit') {
      compositions = compositions
        .map((c) => {
          if (c.composition_cd === composition_cd && !c.is_add_new) {
            return { ...c, is_deleted: true };
          }
          return c;
        })
        .filter((c) => !(c.composition_cd === composition_cd && c.is_add_new));

      setData(
        data && {
          ...data,
          element_compositions: compositions,
        }
      );
    }
  };

  const handleCreateNewElement = async () => {
    if (!data) return;
    if (elementValidationErrors.length > 0 || compositionValidationErrors.length > 0) {
      return;
    }
    const requestData = {
      element_cd: data.element_cd,
      element_name: data.element_name,
      business_format_cd: data.business_format_cd,
      remarks: data.remarks,
      element_classification_cd: data.element_classification_cd,
      suspend_date: data.suspend_date,
      element_unit_master: data.element_unit_master,
      supplementary_unit_1: data.supplementary_unit_1,
      supplementary_unit_2: data.supplementary_unit_2,
      supplementary_unit_conversion_rate_1: data.supplementary_unit_conversion_rate_1,
      supplementary_unit_conversion_rate_2: data.supplementary_unit_conversion_rate_2,
      element_compositions: data.element_compositions.map((composition) => {
        return {
          composition_cd: composition.composition_cd,
          composition_quantity: composition.composition_quantity,
          sort_key: composition.sort_key,
          effective_start_date: composition.effective_start_date,
          effective_end_date: composition.effective_end_date,
          composition_unit_cd: composition.composition_unit_cd,
        };
      }),
    } as ElementConfigurationSettingRequest;

    try {
      const response = await api.create(requestData);
      toast.success(response.message);
      setData(data && { ...data, element_cd: response.data.element_cd });
      history.push(`/calorie-management/parts-configuration-settings/edit/${response.data.element_cd}`);
    } catch (error) {
      console.error('Error fetching data: ', error);
      const { response } = error as AxiosError<ApiError>;
      const { data: res, status } = response ?? {};
      if (status === 400 && res?.message) {
        toast.error(res.message);
      }
    }
  };

  const handleOpenDialogAddComposition = () => {
    handleResetFilter();
    setIsOpenAddCompositionModal(true);
    const currentCompositions = [...(data?.element_compositions ?? [])];
    selectedCompositionsRef.current = currentCompositions.filter((c) => !c.is_deleted).map((c) => c.composition_cd);
    if (element_cd !== undefined) {
      selectedCompositionsRef.current.push(element_cd);
    }
  };

  const handleOpenDialogDetailComposition = (composition_cd: string) => {
    currentCompositionsRef.current = composition_cd;
    setIsOpenDetailCompositionModal(true);
  };

  return (
    <>
      <div className="page-content d-flex flex-column">
        {/* header */}
        <div className="pb-1 border-bottom">
          <Row className="align-items-center">
            <Col xs="4">
              <h4 className="mb-0 fw-bold">
                {actionRef.current === 'edit' && t('PartsConfigurationDetail.head_title')}
                {actionRef.current === 'new' && t('PartsConfigurationDetail.head_title_new')}
              </h4>
            </Col>
            <Col xs="8" className="d-flex justify-content-end">
              <Stack direction="horizontal" gap={4}>
                {actionRef.current === 'edit' && (
                  <GroupButton status={status} lastUpdater={lastUpdater} handleButtonClick={handleApproveButtonClick} />
                )}
                {(actionRef.current === 'new' || actionRef.current === 'copy') && (
                  <Button className="fs-5 fw-bold btn-sm" onClick={handleCreateNewElement}>
                    {t('PartsConfigurationDetail.register')}
                  </Button>
                )}
                <Button
                  className="fs-5 fw-bold btn-sm"
                  onClick={() => history.push('/calorie-management/parts-configuration-settings')}
                >
                  {t('PartsConfigurationDetail.go_back')}
                </Button>
              </Stack>
            </Col>
          </Row>
        </div>

        {/* filter */}
        <div className="container-xxl mt-1">
          <Row className="mb-1 align-items-center">
            <Col className="justify-content-end" xs="auto">
              {t('PartsConfigurationDetail.element_cd')}&nbsp;:&nbsp;
            </Col>
            <Col className="justify-content-start">
              <Form.Control
                type="text"
                className="w-25"
                defaultValue={data?.element_cd}
                disabled={true}
                readOnly={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                name="element_cd"
                onChange={actionRef.current === 'edit' ? () => {} : handleChangeElementProperty}
              />
            </Col>
          </Row>
          <Row className="mb-1 align-items-center">
            <Col className="justify-content-end" xs="auto">
              {t('PartsConfigurationDetail.business_format')}&nbsp;:&nbsp;
            </Col>
            <Col className="justify-content-start">
              <CheckBoxBusinessFormatItem
                selectedValue={
                  data?.business_format_cd !== undefined && data?.business_format_cd !== null
                    ? data.business_format_cd.toString()
                    : ''
                }
                readonly={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                id="business_format_cd"
                name="business_format_cd"
                isInValid={elementValidationErrors.some((error) => error.key === 'business_format_cd')}
                errors={elementValidationErrors.find((error) => error.key === 'business_format_cd')?.errors ?? []}
                onChange={handleInputCheckboxBusinessFormat}
              />
            </Col>
          </Row>
          <Row className="mb-1 align-items-center">
            <Col className="justify-content-end" xs="auto">
              {t('PartsConfigurationDetail.element_classification')}&nbsp;:&nbsp;
            </Col>
            <Col className="justify-content-start" xs="auto">
              <ClassificationItemSelect
                value={
                  data?.element_classification_cd !== undefined && data?.element_classification_cd !== null
                    ? data.element_classification_cd.toString()
                    : ''
                }
                onChange={handleInputElementClassification}
                readonly={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                id="element_classification_cd"
                name="element_classification_cd"
                isInValid={elementValidationErrors.some((error) => error.key === 'element_classification_cd')}
              />
              {elementValidationErrors.some((error) => error.key === 'element_classification_cd') && (
                <Form.Control.Feedback type="invalid">
                  {elementValidationErrors.find((error) => error.key === 'element_classification_cd')?.errors}
                </Form.Control.Feedback>
              )}
            </Col>
          </Row>
          <Row className="mb-1 align-items-center">
            <Col className="justify-content-end" xs="auto">
              {t('PartsConfigurationDetail.element_name')}&nbsp;:&nbsp;
            </Col>
            <Col className="justify-content-start">
              <Form.Control
                type="text"
                className="w-25"
                defaultValue={data?.element_name}
                readOnly={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                onChange={handleChangeElementProperty}
                name="element_name"
                isInvalid={elementValidationErrors.some((error) => error.key === 'element_name')}
              />
              {elementValidationErrors.some((error) => error.key === 'element_name') && (
                <Form.Control.Feedback type="invalid">
                  {elementValidationErrors.find((error) => error.key === 'element_name')?.errors}
                </Form.Control.Feedback>
              )}
            </Col>
          </Row>
          <Row className="mb-1 align-items-center">
            <Col className="justify-content-end" xs="auto">
              {t('PartsConfigurationDetail.suspend_date')}&nbsp;:&nbsp;
            </Col>
            <Col className="justify-content-start">
              <div style={{ maxWidth: '120px', minWidth: '120px' }}>
                <DatePickerCustom
                  value={suspendDate}
                  maxDate={new Date('2099/12/31')}
                  minDate={new Date('1990/01/01')}
                  key={suspendDate ? dateToStringFormat(suspendDate, 'YYYY-MM-DD') : 'null'}
                  onChange={(date: Date) => handleChangeSuspendDate(date)}
                  disabled={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                  name="suspend_date"
                />
              </div>
            </Col>
          </Row>
          <Row className="mb-1 align-items-center">
            <Col className="justify-content-end" xs="auto">
              {t('PartsConfigurationDetail.composition_unit')}&nbsp;:&nbsp;
            </Col>
            <Col className="justify-content-start" xs="auto">
              <Form.Select
                className="form-select"
                aria-label=".form-select-sm"
                name="element_unit_master"
                disabled={
                  data?.status === PENDING_CONFIRMATION ||
                  data?.status === PENDING_APPROVAL ||
                  actionRef.current === 'edit'
                }
                value={data?.element_unit_master || data?.element_units[0].unit_cd}
                onChange={handleChangeElementProperty}
              >
                {data?.element_units.map((unit, index) => (
                  <option key={index} value={unit.unit_cd}>
                    {unit.name}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
          <Row className="mb-1 align-items-center">
            <Col className="justify-content-end" xs="auto">
              {t('PartsConfigurationDetail.element_sub_unit_1')}&nbsp;:&nbsp;
            </Col>
            <Col className="justify-content-start" xs="auto">
              <Form.Select
                className="form-select"
                aria-label=".form-select-sm"
                name="supplementary_unit_1"
                disabled={
                  data?.status === PENDING_CONFIRMATION ||
                  data?.status === PENDING_APPROVAL ||
                  actionRef.current === 'edit'
                }
                value={data?.supplementary_unit_1 || data?.element_units[0].unit_cd}
                onChange={handleChangeElementProperty}
              >
                {data?.element_units.map((unit, index) => (
                  <option key={index} value={unit.unit_cd}>
                    {unit.name}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col className="justify-content-end" xs="auto">
              {t('PartsConfigurationDetail.subunit_conversion_rate_1')}&nbsp;:&nbsp;
            </Col>
            <Col className="justify-content-start" xs="auto">
              <Form.Control
                type="number"
                defaultValue={data?.supplementary_unit_conversion_rate_1 ?? ''}
                step={0.01}
                min={0}
                max={9999}
                readOnly={
                  data?.status === PENDING_CONFIRMATION ||
                  data?.status === PENDING_APPROVAL ||
                  actionRef.current === 'edit'
                }
                disabled={actionRef.current === 'edit'}
                onInput={(e) => handleChangeElementProperty(e as React.ChangeEvent<HTMLInputElement>)}
                name="supplementary_unit_conversion_rate_1"
                isInvalid={elementValidationErrors.some(
                  (error) => error.key === 'supplementary_unit_conversion_rate_1'
                )}
              />
              {elementValidationErrors.some((error) => error.key === 'supplementary_unit_conversion_rate_1') && (
                <Form.Control.Feedback type="invalid">
                  {
                    elementValidationErrors.find((error) => error.key === 'supplementary_unit_conversion_rate_1')
                      ?.errors
                  }
                </Form.Control.Feedback>
              )}
            </Col>
          </Row>
          <Row className="mb-1 align-items-center">
            <Col className="justify-content-end" xs="auto">
              {t('PartsConfigurationDetail.element_sub_unit_2')}&nbsp;:&nbsp;
            </Col>
            <Col className="justify-content-start" xs="auto">
              <Form.Select
                className="form-select"
                aria-label=".form-select-sm"
                name="supplementary_unit_2"
                disabled={
                  data?.status === PENDING_CONFIRMATION ||
                  data?.status === PENDING_APPROVAL ||
                  actionRef.current === 'edit'
                }
                value={data?.supplementary_unit_2 || data?.element_units[0].unit_cd}
                onChange={handleChangeElementProperty}
              >
                {data?.element_units.map((unit, index) => (
                  <option key={index} value={unit.unit_cd}>
                    {unit.name}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col className="justify-content-end" xs="auto">
              {t('PartsConfigurationDetail.subunit_conversion_rate_2')}&nbsp;:&nbsp;
            </Col>
            <Col className="justify-content-start" xs="auto">
              <Form.Control
                type="number"
                defaultValue={data?.supplementary_unit_conversion_rate_2 ?? ''}
                step={0.01}
                min={0}
                max={9999}
                isInvalid={elementValidationErrors.some(
                  (error) => error.key === 'supplementary_unit_conversion_rate_2'
                )}
                disabled={actionRef.current === 'edit'}
                readOnly={
                  data?.status === PENDING_CONFIRMATION ||
                  data?.status === PENDING_APPROVAL ||
                  actionRef.current === 'edit'
                }
                onInput={(e) => handleChangeElementProperty(e as React.ChangeEvent<HTMLInputElement>)}
                name="supplementary_unit_conversion_rate_2"
              />
              {elementValidationErrors.some((error) => error.key === 'supplementary_unit_conversion_rate_2') && (
                <Form.Control.Feedback type="invalid">
                  {
                    elementValidationErrors.find((error) => error.key === 'supplementary_unit_conversion_rate_2')
                      ?.errors
                  }
                </Form.Control.Feedback>
              )}
            </Col>
          </Row>
          {/* list */}
          <Row className="mb-1 align-items-center">
            <Col>{t('PartsConfigurationDetail.composition')}&nbsp;:&nbsp;</Col>
            {actionRef.current === 'edit' && (
              <Col className="d-flex align-items-center">
                <span className="text-nowrap">{t('PartsConfigurationDetail.application_date')}&nbsp;:&nbsp;</span>
                <div style={{ maxWidth: '120px', minWidth: '120px' }}>
                  <DatePickerCustom
                    name="application_date"
                    value={applicationDate}
                    key={applicationDate ? dateToStringFormat(applicationDate, 'YYYY-MM-DD') : 'null'}
                    onChange={(date: Date) => setApplicationDate(date)}
                  />
                </div>
                <Button
                  className="btn-primary  ms-2 me-3"
                  disabled={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                  onClick={() => handleFilterApplicableDate(applicationDate)}
                >
                  {t('PartsConfigurationDetail.filter')}
                </Button>
                <Button
                  className="btn-primary  ms-2 me-3"
                  disabled={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                  onClick={handleResetFilter}
                >
                  {t('PartsConfigurationDetail.reset')}
                </Button>
              </Col>
            )}
            <Col className="align-items-center text-center">
              <Button
                className="btn-primary"
                disabled={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                onClick={handleOpenDialogAddComposition}
              >
                {t('PartsConfigurationDetail.add')}
              </Button>
            </Col>
          </Row>
          <div className="mb-1 table-component">
            <div className="div-table">
              <div className="div-thead div-tr" style={{ zIndex: '1' }}>
                <div
                  className="div-th d-flex align-items-center justify-content-center lh-1"
                  style={{ width: `${COLUMN_WIDTH[0]}rem` }}
                >
                  {t('PartsConfigurationDetail.table.changed')}
                </div>
                <div
                  className="div-th d-flex align-items-center justify-content-center lh-1"
                  style={{ width: `${COLUMN_WIDTH[1]}rem` }}
                >
                  {t('PartsConfigurationDetail.table.configuration_cd')}
                </div>
                <div
                  className="div-th d-flex align-items-center justify-content-center lh-1"
                  style={{ width: `${COLUMN_WIDTH[2]}rem` }}
                >
                  {t('PartsConfigurationDetail.table.configuration_name')}
                </div>
                <div
                  className="div-th d-flex align-items-center justify-content-center lh-1"
                  style={{ width: `${COLUMN_WIDTH[3]}rem` }}
                >
                  {t('PartsConfigurationDetail.table.usage_amount')}
                </div>
                <div
                  className="div-th d-flex align-items-center justify-content-center lh-1"
                  style={{ width: `${COLUMN_WIDTH[4]}rem` }}
                >
                  {t('PartsConfigurationDetail.table.sort_key')}
                </div>
                <div
                  className="div-th d-flex align-items-center justify-content-center lh-1"
                  style={{ width: `${COLUMN_WIDTH[5]}rem` }}
                >
                  {t('PartsConfigurationDetail.table.application_start_date')}
                </div>
                <div
                  className="div-th d-flex align-items-center justify-content-center lh-1"
                  style={{ width: `${COLUMN_WIDTH[6]}rem` }}
                >
                  {t('PartsConfigurationDetail.table.application_end_date')}
                </div>
                <div
                  className="div-th d-flex align-items-center justify-content-center lh-1"
                  style={{ width: `${COLUMN_WIDTH[7]}rem` }}
                >
                  {t('PartsConfigurationDetail.table.delete')}
                </div>
              </div>
              {data &&
                data?.element_compositions &&
                data.element_compositions
                  .filter((elm) => !elm.is_deleted && !elm.is_hidden)
                  .map((elm: ElementCompositionResponse, idx) => {
                    const error = compositionValidationErrors.find((error) => error.key === elm.composition_cd);
                    const units = elm.supplier_item_cd ? data.purchase_units : data.element_units;
                    const process_units =
                      [
                        elm.process_item_unit_cd,
                        elm.process_item_sub_unit_cd_1,
                        elm.process_item_sub_unit_cd_2,
                        elm.process_item_sub_unit_cd_3,
                      ].filter((val) => val !== '' && val !== null && val !== undefined && val !== '0') || [];

                    const purchase_units = [
                      elm.purchase_item_unit_cd,
                      elm.purchase_item_sub_unit_cd_1,
                      elm.purchase_item_sub_unit_cd_2,
                      elm.purchase_item_sub_unit_cd_3,
                    ].filter((val) => val !== '' && val !== null && val !== undefined && val !== '0');

                    const element_units = [
                      elm.element_unit_master_assoc,
                      elm.supplementary_unit_1_assoc,
                      elm.supplementary_unit_2_assoc,
                    ].filter((val) => val !== '' && val !== null && val !== undefined && val !== '0') as string[];

                    const unit_cds = elm.supplier_item_cd
                      ? process_units.length > 0
                        ? process_units
                        : purchase_units
                      : element_units;

                    return (
                      <div
                        className={`div-tr ${elm.is_add_new ? 'div-tr-selected' : ''}`}
                        key={elm.composition_cd + idx}
                      >
                        <div
                          className="div-td d-flex align-items-center justify-content-center lh-1"
                          style={{ width: `${COLUMN_WIDTH[0]}rem` }}
                        >
                          {elm.change_flg ? <span className="text-danger fs-4 lh-1">●</span> : <span></span>}
                        </div>
                        <div
                          className="div-td d-flex align-items-center justify-content-center lh-1"
                          style={{ width: `${COLUMN_WIDTH[1]}rem` }}
                        >
                          <button
                            type="button"
                            onClick={() => handleOpenDialogDetailComposition(elm.composition_cd)}
                            className="btn btn-link p-0"
                            style={{ color: '#3d8bfd' }}
                          >
                            {elm.composition_cd}
                          </button>
                        </div>
                        <div
                          className="div-td d-flex align-items-center justify-content-center lh-1"
                          style={{ width: `${COLUMN_WIDTH[2]}rem` }}
                        >
                          {elm.element_assoc_name || elm.process_item_name || elm.purchase_item_name}
                        </div>
                        <div
                          className="div-td align-items-start justify-content-center lh-1"
                          style={{ width: `${COLUMN_WIDTH[3]}rem` }}
                        >
                          <div className="row g-1">
                            <div className="col">
                              <Form.Control
                                className="me-1"
                                type="number"
                                min={0}
                                step={0.1}
                                name="composition_quantity"
                                readOnly={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                                defaultValue={
                                  elm.composition_quantity !== undefined ? elm.composition_quantity.toFixed(1) : ''
                                }
                                isInvalid={
                                  error?.errors?.some((err) => err.key.includes('composition_quantity')) ?? false
                                }
                                onChange={(e) =>
                                  handleChangeElementCompositionQuantity(e.target.value, elm.composition_cd)
                                }
                              />
                              {error && (
                                <Form.Control.Feedback type="invalid" className="">
                                  {error.errors.find((err) => err.key.includes('composition_quantity'))?.errors}
                                </Form.Control.Feedback>
                              )}
                            </div>
                            <div className="col">
                              <Form.Select
                                as="select"
                                disabled={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                                value={
                                  elm.composition_unit_cd ||
                                  (elm.supplier_item_cd
                                    ? data.purchase_units[0].unit_cd ?? ''
                                    : data.element_units[0].unit_cd ?? '')
                                }
                                name="composition_unit_cd"
                                onChange={(e) => handleOnChangeUsageUnit(e.target.value, elm.composition_cd)}
                              >
                                {units
                                  .filter((unit) => unit_cds.includes(unit.unit_cd))
                                  .map((unit) => (
                                    <option key={unit.unit_cd} value={unit.unit_cd}>
                                      {unit.name}
                                    </option>
                                  ))}
                              </Form.Select>
                            </div>
                          </div>
                        </div>
                        <div
                          className="div-td align-items-start justify-content-center lh-1"
                          style={{ width: `${COLUMN_WIDTH[4]}rem` }}
                        >
                          <Form.Control
                            type="number"
                            min={0}
                            step={1}
                            name="sort_key"
                            readOnly={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                            defaultValue={elm.sort_key !== undefined || elm.sort_key !== null ? elm.sort_key : ''}
                            className="form-control"
                            isInvalid={error?.errors?.some((err) => err.key.includes('sort_key')) ?? false}
                            onChange={(e) => handleChangeElementCompositionSortKey(e.target.value, elm.composition_cd)}
                          />
                          <Form.Control.Feedback type="invalid" className="">
                            {error?.errors.find((err) => err.key.includes('sort_key'))?.errors}
                          </Form.Control.Feedback>
                        </div>
                        <div
                          className="div-td align-items-start justify-content-center lh-1"
                          style={{ width: `${COLUMN_WIDTH[5]}rem` }}
                        >
                          <DatePickerCustom
                            name="effective_start_date"
                            value={elm.effective_start_date ? new Date(elm.effective_start_date) : null}
                            maxDate={elm.effective_end_date ? new Date(elm.effective_end_date) : null}
                            onChange={(date: Date) => handleChangeComposEffectiveStartDate(date, elm.composition_cd)}
                            isInvalid={error?.errors?.some((err) => err.key.includes('effective_start_date')) ?? false}
                            errors={error?.errors.find((err) => err.key.includes('effective_start_date'))?.errors}
                            disabled={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                          />
                        </div>
                        <div
                          className="div-td align-items-start justify-content-center lh-1"
                          style={{ width: `${COLUMN_WIDTH[6]}rem` }}
                        >
                          <DatePickerCustom
                            name="effective_end_date"
                            value={elm.effective_end_date ? new Date(elm.effective_end_date) : null}
                            minDate={elm.effective_start_date ? new Date(elm.effective_start_date) : null}
                            onChange={(date: Date) => handleChangeComposEffectiveEndDate(date, elm.composition_cd)}
                            isInvalid={error?.errors?.some((err) => err.key.includes('effective_end_date')) ?? false}
                            errors={error?.errors.find((err) => err.key.includes('effective_end_date'))?.errors}
                            disabled={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                          />
                        </div>
                        <div
                          className="div-td d-flex align-items-start justify-content-center lh-1"
                          style={{ width: `${COLUMN_WIDTH[7]}rem` }}
                        >
                          <Button
                            className="btn btn-primary"
                            variant="primary"
                            disabled={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                            onClick={() => handleDeleteElementComposition(elm.composition_cd)}
                          >
                            {t('PartsConfigurationDetail.table.delete')}
                          </Button>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
          <div className="mb-1 fw-bold">{t('CalorieAllergySettingDetail.remark')}</div>
          <div className="row g-0 ">
            <div className="col-md-6 col-sm-12 bg-main p-1 text-center border">
              {t('CalorieAllergySettingDetail.table.remark')}
            </div>
          </div>
          <div className="row g-0">
            <div className="col-md-6 col-sm-12 border border-top-0">
              <textarea
                rows={3}
                onChange={handleChangeElementProperty}
                value={data?.remarks}
                disabled={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                className="form-control border-0 textarea-row"
                name="remarks"
              />
            </div>
          </div>
        </div>
      </div>
      {isOpenAddCompositionModal && (
        <SearchCompositionModal
          onClose={() => setIsOpenAddCompositionModal(false)}
          onClickAddComposition={handleAddComposition}
          selectedCompositions={selectedCompositionsRef.current}
        />
      )}
      {isOpenDetailCompositionModal && (
        <ViewDetailCompositionModal
          onClose={() => setIsOpenDetailCompositionModal(false)}
          chosenDetailComposition={currentCompositionsRef.current}
        />
      )}
    </>
  );
}

export default PartsConfigurationDetailSetting;
