import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DraftChartState {
  data: any;
}

const initialState: DraftChartState = {
  data: {},
};

const draftChartSlice = createSlice({
  name: 'draft_chart',
  initialState,
  reducers: {
    addDraftChart(state, action: PayloadAction<{ key: string; data: any }>) {
      state.data = {
        ...state.data,
        [action.payload.key]: action.payload.data,
      };
    },
    removeDraftChart(state, action: PayloadAction<{ key: string }>) {
      delete state.data[action.payload.key];
    },
    clearAllDraftChart(state) {
      state.data = {};
    },
  },
});

// Actions
export const draftChartActions = draftChartSlice.actions;

// Selectors
export const selectDraftChartData = (state: any) => {
  return state.draftChart?.data;
};

// Reducer
export const draftChartReducer = draftChartSlice.reducer;
