import { useAppSelector } from 'app/hooks';
import menuCategoryMasterService from 'features/MasterDataManagement/MenuCategoryMaster/menuCategoryMasterService';
import nutritionMasterService from 'features/MasterDataManagement/NutritionMaster/nutritionMasterService';
import nutritionUnitMasterService from 'features/MasterDataManagement/NutritionUnitMaster/nutritionUnitMasterService';
import { MouseEvent, useEffect, useState } from 'react';
import { Button, Col, Form, FormControl, InputGroup, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';
import { TfiAngleLeft, TfiAngleRight } from 'react-icons/tfi';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  businessFormatMasterActions,
  selectBusinessFormatMasterData,
} from 'store/BusinessFormatMaster/businessFormatMasterSlice';
import '../../style.scss';
import MenuStructureSettingService from '../menuStructureSettingService';
import { MenuTable } from './components/menuTable';
import {
  PENDING_APPROVAL,
  PENDING_CONFIRMATION,
  TEMPORARY_SAVE,
} from 'features/CalorieManagement/PurchaseProcessedCalorieAllergySetting/constant';

interface Filter {
  business?: string;
  menuCategory?: number;
  status: string;
  notice: string;
  applicablePeriod: string;
}

const STATUS_RADIO_CHECK_BOX = [
  {
    label: 'all',
    value: 'all',
    group: 'status',
  },
  {
    label: 'confirming',
    value: PENDING_CONFIRMATION,
    group: 'status',
  },
  {
    label: 'pending_approval',
    value: PENDING_APPROVAL,
    group: 'status',
  },
  {
    label: 'temporary_save',
    value: TEMPORARY_SAVE,
    group: 'status',
  },
];

const NOTICE_RADIO_CHECK_BOX = [
  {
    label: 'all',
    value: 'all',
    group: 'notice',
  },
  {
    label: 'only_changed',
    value: 'changed',
    group: 'notice',
  },
];

const APPLICABLE_PERIOD_RADIO_CHECK_BOX = [
  {
    label: 'all',
    value: 'all',
    group: 'applicable_period',
  },
  {
    label: 'only_validity',
    value: 'valid',
    group: 'applicable_period',
  },
];

const MenuStructureSettingList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const businessFormatMasterList = useAppSelector(selectBusinessFormatMasterData);

  // state
  const [searchText, setSearchText] = useState<string>('');
  const [filter, setFilter] = useState<Filter>({
    business: undefined,
    menuCategory: undefined,
    status: STATUS_RADIO_CHECK_BOX[0].value,
    notice: NOTICE_RADIO_CHECK_BOX[0].value,
    applicablePeriod: APPLICABLE_PERIOD_RADIO_CHECK_BOX[0].value,
  });
  const [selectedId, setSlectedId] = useState<number | null>(null);
  const [menus, setMenus] = useState<any>([]);
  const [page, setPage] = useState<any>({
    totalPage: 0,
    curPage: 1,
  });
  const [menuCategoryOptions, setMenuCategoryOptions] = useState<any>([]);
  const [nutritionMasterHeaders, setNutritionMasterHeaders] = useState<any>([]);

  // call API
  const fetchMenu = async (page: number) => {
    try {
      if (page === 0) return;
      if (filter.business === undefined || filter.menuCategory === undefined) return;
      const res = await MenuStructureSettingService.getAll({ searchText, page, ...filter });
      setMenus(res.data?.data);
      setSlectedId(null);
      setPage({
        curPage: page,
        totalPage: res.data.pagination.total_pages,
      });
      return res;
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const fetchNutritionMaster = async () => {
    try {
      const nutritionRes = await nutritionMasterService.getAll();
      const unitRes = await nutritionUnitMasterService.getAll();
      const res = nutritionRes.data.map((nutrition) => {
        return {
          ...nutrition,
          nutrition_unit: `(${
            unitRes.data.find((unit) => unit.nutrition_unit_cd === nutrition.nutrition_unit_master)?.nutrition_unit_name
          })`,
        };
      });
      setNutritionMasterHeaders(res);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  useEffect(() => {
    if (businessFormatMasterList.length === 0) {
      dispatch(businessFormatMasterActions.fetchBusinessFormatMasterList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (businessFormatMasterList.length === 0) return;
    const defaultBusiness = businessFormatMasterList[0]?.business_format_cd;
    setFilter((filter) => ({
      ...filter,
      business: defaultBusiness,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessFormatMasterList]);

  const fetchMenuCategoryMaster = async (businessType?: string) => {
    try {
      if (businessType === undefined) return;
      const res = await menuCategoryMasterService.getAll({ businessType: businessType });
      const defaultMenuCategory = res.data[0]?.menu_category_cd ?? '';
      setMenuCategoryOptions(res.data);
      setFilter((filter) => ({
        ...filter,
        menuCategory: defaultMenuCategory,
      }));
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  useEffect(() => {
    fetchNutritionMaster();
  }, []);

  useEffect(() => {
    fetchMenuCategoryMaster(filter.business);
  }, [filter.business]);

  useEffect(() => {
    fetchMenu(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.menuCategory, filter.status, filter.notice, filter.applicablePeriod]);

  // register func
  const handleRegister = (event: MouseEvent<HTMLElement>) => {
    history.push(`/calorie-management/menus-structure-setting/create`);
  };

  const handleEdit = (event: MouseEvent<HTMLElement>) => {
    history.push(`/calorie-management/menus-structure-setting/edit/${selectedId}`);
  };

  const handleCopyAndRegister = (event: MouseEvent<HTMLElement>) => {
    history.push(`/calorie-management/menus-structure-setting/copy/${selectedId}`);
  };

  const handlePreviousPageClick = (event: MouseEvent<HTMLElement>) => {
    if (page.curPage >= 1) {
      setPage({
        ...page,
        curPage: page.curPage - 1,
      });
      fetchMenu(page.curPage - 1);
    }
  };

  const handleNextPageClick = (event: MouseEvent<HTMLElement>) => {
    if (page.curPage <= page.totalPage) {
      setPage({
        ...page,
        curPage: page.curPage + 1,
      });
    }
    fetchMenu(page.curPage + 1);
  };

  const handleChangePage = (event: any) => {
    const value = event.target.value;
    if (value <= page.totalPage && value >= 0) {
      setPage({
        ...page,
        curPage: Number(value),
      });
      fetchMenu(Number(value));
    }
  };

  const handleBlurPage = (event: any) => {
    const value = event.target.value;
    if (Number(value) === 0) {
      setPage({
        ...page,
        curPage: 1,
      });
      fetchMenu(1);
    }
  };

  const handleSelect = (id: number) => {
    if (selectedId === id) {
      setSlectedId(null);
    } else {
      setSlectedId(id);
    }
  };

  const handleApplySearch = () => {
    fetchMenu(1);
  };

  return (
    <div className="page-content d-flex flex-column">
      {/* Header */}
      <div className="pb-3 border-bottom">
        <Row>
          <Col xs="4">
            <h4 className="mb-0">{t('MenuStructureSettingScreen.list_head_title')}</h4>
          </Col>
          <Col xs="8" className="d-flex justify-content-end">
            <Stack direction="horizontal" gap={4}>
              <Button variant="secondary" onClick={handleEdit} disabled={!selectedId}>
                {t('MenuStructureSettingScreen.edit')}
              </Button>
              <Button onClick={handleCopyAndRegister} disabled={!selectedId}>
                {t('MenuStructureSettingScreen.copy_and_register')}
              </Button>
              <Button onClick={handleRegister}>{t('MenuStructureSettingScreen.sign_up')}</Button>
            </Stack>
          </Col>
        </Row>
      </div>

      {/* Filter form  */}
      <div className="py-3">
        <Row className="align-items-center mb-3 gap-4">
          {/* Search, select filter  */}
          <Row>
            <Col xs="auto">
              <Row className="align-items-center">
                <Col xs="auto">
                  <InputGroup>
                    <FormControl
                      onChange={(e) => setSearchText(e.target.value)}
                      placeholder={t('MenuStructureSettingScreen.name_code_search')}
                      aria-label="Search"
                    />
                    <InputGroup.Text>
                      <FaSearch onClick={handleApplySearch} />
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
                <Col xs="auto"></Col>
              </Row>
            </Col>

            <Col xs="auto">
              <Row className="align-items-center">
                <Form.Label column className="text-start">
                  {t('MenuStructureSettingScreen.business')}
                </Form.Label>

                <Col xs="auto">
                  <Form.Select
                    style={{ width: '200px' }}
                    value={filter.business}
                    onChange={(e) =>
                      setFilter({
                        ...filter,
                        business: e.target.value,
                      })
                    }
                  >
                    {businessFormatMasterList.map((option: any, idx: number) => {
                      return (
                        <option key={idx} value={option.business_format_cd}>
                          {option.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>
            </Col>

            <Col xs="auto">
              <Row className="align-items-center">
                <Form.Label column className="text-start">
                  {t('MenuStructureSettingScreen.menu_category')}
                </Form.Label>

                <Col xs="auto">
                  <Form.Select
                    disabled={filter.business === undefined}
                    style={{ width: '200px' }}
                    value={filter.menuCategory}
                    onChange={(e) =>
                      setFilter({
                        ...filter,
                        menuCategory: Number(e.target.value),
                      })
                    }
                  >
                    {menuCategoryOptions.map((option: any, idx: number) => {
                      return (
                        <option key={idx} value={option.menu_category_cd}>
                          {option.menu_category_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* Radio button filter  */}
          <Row>
            <Col xs="auto">
              <Row className="align-items-center">
                <Form.Label column className="fw-bold mb-0 text-start">
                  {t('MenuStructureSettingScreen.status')}
                </Form.Label>
                <Col xs="auto">
                  {STATUS_RADIO_CHECK_BOX.map((statusCheckbox, idx) => (
                    <Form.Check
                      key={idx}
                      className="anra-checkbox"
                      inline
                      type="radio"
                      id={`${statusCheckbox.group}_${idx}`}
                      label={t(`MenuStructureSettingScreen.${statusCheckbox.label}`)}
                      name={statusCheckbox.group}
                      checked={filter.status === STATUS_RADIO_CHECK_BOX[idx].value}
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          status: statusCheckbox.value,
                        })
                      }
                    />
                  ))}
                </Col>
              </Row>
            </Col>

            <Col xs="auto">
              <Row className="align-items-center">
                <Form.Label column className="fw-bold mb-0 text-start">
                  {t('MenuStructureSettingScreen.notice')}
                </Form.Label>

                <Col xs="auto">
                  {NOTICE_RADIO_CHECK_BOX.map((noticeCheckbox, idx) => (
                    <Form.Check
                      key={idx}
                      className="anra-checkbox"
                      inline
                      type="radio"
                      id={`${noticeCheckbox.group}_${idx}`}
                      label={t(`MenuStructureSettingScreen.${noticeCheckbox.label}`)}
                      name={noticeCheckbox.group}
                      checked={filter.notice === NOTICE_RADIO_CHECK_BOX[idx].value}
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          notice: noticeCheckbox.value,
                        })
                      }
                    />
                  ))}
                </Col>
              </Row>
            </Col>

            <Col xs="auto">
              <Row className="align-items-center">
                <Form.Label column className="fw-bold mb-0 text-start">
                  {t('MenuStructureSettingScreen.applicable_period')}
                </Form.Label>

                <Col xs="auto">
                  {APPLICABLE_PERIOD_RADIO_CHECK_BOX.map((applicablePeriodCheckbox, idx) => (
                    <Form.Check
                      key={idx}
                      className="anra-checkbox"
                      inline
                      type="radio"
                      id={`${applicablePeriodCheckbox.group}_${idx}`}
                      label={t(`MenuStructureSettingScreen.${applicablePeriodCheckbox.label}`)}
                      name={applicablePeriodCheckbox.group}
                      checked={filter.applicablePeriod === APPLICABLE_PERIOD_RADIO_CHECK_BOX[idx].value}
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          applicablePeriod: applicablePeriodCheckbox.value,
                        })
                      }
                    />
                  ))}
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </div>

      {/* Pagination */}
      <div className="py-2">
        <div className="anra-pagination d-inline-flex flex-row gap-1 align-items-center">
          <Button
            disabled={page.curPage <= 1}
            size="sm"
            variant="primary"
            className="btn--auto me-1"
            onClick={handlePreviousPageClick}
          >
            <TfiAngleLeft size={16} />
          </Button>
          <Form.Control
            size="sm"
            type="text"
            className="d-inline-block text-center"
            style={{ width: '5rem' }}
            value={page.curPage}
            onChange={handleChangePage}
            onBlur={handleBlurPage}
            min={1}
            max={page.totalPage}
            disabled
          />
          <span>/{page.totalPage}ページ</span>
          <Button
            disabled={page.curPage >= page.totalPage}
            size="sm"
            variant="primary"
            className="btn--auto ms-1"
            onClick={handleNextPageClick}
          >
            <TfiAngleRight size={16} />
          </Button>
          <div style={{ marginLeft: '12px' }}>
            <div className="text-primary fw-bold">{t('MenuStructureSettingScreen.icon_note')}</div>
            <div className="text-primary fw-bold">{t('MenuStructureSettingScreen.nutritional_note')}</div>
          </div>
        </div>
      </div>

      {/* Table */}
      <MenuTable
        menus={menus}
        onSelect={handleSelect}
        selectedId={selectedId}
        nutritionHeaders={nutritionMasterHeaders}
      />
    </div>
  );
};

export default MenuStructureSettingList;
