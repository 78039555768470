import { Form, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const HEADER = [
  {
    title: '選択',
    width: '10%',
  },
  {
    title: 'コード',
    width: '30%',
  },
  {
    title: '名称',
    width: '60%',
  },
];

const MenuChartModalTable = ({
  menus,
  onSelect,
  selectedIds,
}: {
  menus: any[];
  onSelect: (id: number) => void;
  selectedIds: number[];
}) => {
  const { t } = useTranslation();
  return (
    <div className="sticky-table flex-grow-1 flex-shrink-1" style={{ height: '400px' }}>
      <Table>
        <thead>
          <tr>
            {HEADER.map((header, idx) => (
              <th style={{ width: header.width }} key={idx}>
                {t(`${header.title}`)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {menus?.map((menu, idx) => {
            if (menu?.isCategory) return <></>;
            return (
              <tr key={idx}>
                <td>
                  <Form.Check
                    className="anra-checkbox d-flex justify-content-center"
                    label=""
                    type="checkbox"
                    onChange={() => onSelect(menu.menu_cd)}
                    checked={selectedIds.includes(menu.menu_cd)}
                  />
                </td>
                <td>{menu.menu_cd}</td>
                <td>{menu.menu_name}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default MenuChartModalTable;
