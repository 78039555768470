import { AxiosError, AxiosResponse } from 'axios';
import { errorNotificationActions } from 'components/common/ErrorNotification/errorNotificationSlice';
import authSaga from 'features/Auth/authSaga';
import menuStructureSettingSaga from 'features/CalorieManagement/MenuStructureSetting/screens/menuStructureSettingSaga';
import { StatusCodes } from 'http-status-codes';
import i18next from 'i18next';
import { ApiError } from 'models';
import { toast } from 'react-toastify';
import { all, put } from 'redux-saga/effects';
import allergyItemMasterSaga from 'store/AllergyItemMaster/allergyItemMasterSaga';
import allergyMasterSaga from 'store/AllergyMaster/allergyMasterSaga';
import businessFormatMasterSaga from 'store/BusinessFormatMaster/businessFormatMasterSaga';
import elementClassificationMasterSaga from 'store/ElementClassificationMaster/elementClassificationMasterSaga';
import menuCategoryMasterSaga from 'store/MenuCategoryMaster/menuCategoryMasterSaga';
import nutritionMasterSaga from 'store/NutritionMaster/nutritionMasterSaga';
import nutritionUnitMasterSaga from 'store/NutritionUnitMaster/nutritionUnitMasterSaga';
import partsConfigurationSaga from 'features/CalorieManagement/PartsConfigurationSetting/Saga';

export function* handleResponseError(e: AxiosError) {
  const { t } = i18next;

  if (e.response) {
    if (e.response.status !== StatusCodes.UNAUTHORIZED) {
      yield put(errorNotificationActions.setError(e as AxiosError<ApiError>));
    }
  } else {
    console.error(e);
    toast.error(`${t('App.unexpected_error')}`);
  }
}

export function* handleResponseMessage(res: AxiosResponse) {
  yield put(errorNotificationActions.setMessage(res));
}

export default function* rootSaga() {
  yield all([
    authSaga(),
    allergyMasterSaga(),
    allergyItemMasterSaga(),
    menuStructureSettingSaga(),
    nutritionUnitMasterSaga(),
    elementClassificationMasterSaga(),
    nutritionMasterSaga(),
    businessFormatMasterSaga(),
    menuCategoryMasterSaga(),
    partsConfigurationSaga(),
  ]);
}
