import { PayloadAction } from '@reduxjs/toolkit';
import { handleResponseError } from 'app/rootSaga';
import { AxiosError } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import api from './Service';
import { ElementConfigurationSettingList } from './models/model';
import { partsConfigurationActions } from './Slice';
import { PaginationResponse } from 'models/common';

function* yieldFetchPartsConfiguration(action: PayloadAction<string>) {
  const url = action.payload;
  try {
    const response: PaginationResponse<ElementConfigurationSettingList> = yield call(api.getAll, url);
    yield put(partsConfigurationActions.fetchPartsConfigurationSuccess(response));
  } catch (e) {
    yield put(partsConfigurationActions.fetchPartsConfigurationFailed());
    yield handleResponseError(e as AxiosError);
  }
}

export default function* partsConfigurationSaga() {
  yield takeLatest(partsConfigurationActions.fetchPartsConfiguration.type, yieldFetchPartsConfiguration);
}
