export const SPECIFIC_RAW_MATERIALS = '特定原材料';
export const ITEMS_EQUIVALENT_TO_SPECIFIC_RAW_MATERIALS = '特定原材料に準ずる品目';

export const NEW = '新規';
export const PENDING_CONFIRMATION = '確認待ち';
export const CONFIRMED = '確認済';
export const PENDING_APPROVAL = '承認待ち';
export const TEMPORARY_SAVE = '一時保存';
export const APPROVED = '承認済み';
export const SYSTEM_ID = '1035';

export enum AuthorityLevels {
  ADMIN = 999,
  APPROVAL = 200,
  NORMAL = 100,
}
