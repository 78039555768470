import { yupResolver } from '@hookform/resolvers/yup';
import SearchCompositionModal from 'features/SearchCompositionModal';
import ViewDetailProductModal from 'features/ViewDetailCompositionModal';
import { MouseEvent, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Stack } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { MenuCompositionStructureSetting, MenuStructureSetting } from '../../models';
import '../../style.scss';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import menuCategoryMasterService from 'features/MasterDataManagement/MenuCategoryMaster/menuCategoryMasterService';
import { toast } from 'react-toastify';
import {
  businessFormatMasterActions,
  selectBusinessFormatMasterData,
} from 'store/BusinessFormatMaster/businessFormatMasterSlice';
import MenuStructureSettingService from '../menuStructureSettingService';
import { menuStructureSettingActions, selectMenuStructureSettingData } from '../menuStructureSettingSlice';
import { CreateTable } from './components/compositionTable';
import PurchaseUnitMasterService from '../purchaseUnitMastersService';
import ElementUnitMasterService from '../elementUnitMastersService';
import { DEFAULT_EFFECTIVE_END_DATE, DEFAULT_SUSPENSION_DATE } from '../../constants/defaultDate';
import dayjs from 'dayjs';
import { patternDigitAfterComma } from '../../constants/decimal';
import DatePickerCustom from 'features/CalorieManagement/PartsConfigurationSetting/components/DatePickerCustom';

type MenuStructureSettingForm = Omit<MenuStructureSetting, 'id' | 'status' | 'confirmUserId'>;

const MenuStructureSettingCreate = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isAllowedEdit = true; // alway allow edit
  const menuStructureData = useAppSelector(selectMenuStructureSettingData) as any;
  const businessFormatMasterList = useAppSelector(selectBusinessFormatMasterData);

  const defaultValues = {
    suspension_date: DEFAULT_SUSPENSION_DATE,
    display_menu: false,
    compositions: [],
  };

  // state
  const [isOpenAddCompositionModal, setIsOpenAddCompositionModal] = useState(false);
  const [isDetailCompositionModal, setIsDetailCompositionModal] = useState(false);
  const [chosenDetailComposition, setChosenDetailComposition] = useState<string | null>(null);
  const [menuCategoryOptions, setMenuCategoryOptions] = useState<any>([]);
  const [purchaseUnitMasters, setPurchaseUnitMasters] = useState<any>([]);
  const [elementUnitMasters, setElementUnitMasters] = useState<any>([]);

  // param
  const { id } = useParams<{ id: string }>();

  // call API
  useEffect(() => {
    if (!id) return;
    dispatch(menuStructureSettingActions.fetchMenuStructureSetting({ id: id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (businessFormatMasterList.length === 0) {
      dispatch(businessFormatMasterActions.fetchBusinessFormatMasterList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // yup setup
  const schema = yup.object({
    menu_code: yup.string(),
    menu_name: yup.string().required(t('MenuStructureSettingScreen.warn_field_required')),
    menu_category: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(t('MenuStructureSettingScreen.warn_field_required')),
    business: yup.string().required(t('MenuStructureSettingScreen.warn_field_required')),
    suspension_date: yup.date().required(t('MenuStructureSettingScreen.warn_field_required')),
    sort_key: yup
      .number()
      .min(0, t('MenuStructureSettingScreen.negative_number'))
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(),
    display_menu: yup.boolean().required(t('MenuStructureSettingScreen.warn_field_required')),
    compositions: yup.array(
      yup.object({
        changed: yup.boolean(),
        name: yup.string().required(),
        code: yup.string().required(),
        usage_amount: yup
          .number()
          .min(0, t('MenuStructureSettingScreen.negative_number'))
          .transform((value) => (isNaN(value) ? undefined : value))
          .test('is-decimal', t('MenuStructureSettingScreen.one_degit_after_comma'), (val: any) => {
            if (val !== undefined) {
              return patternDigitAfterComma.test(val);
            }
            return true;
          })
          .required(t('MenuStructureSettingScreen.warn_field_required')),
        sort_key: yup
          .number()
          .min(0, t('MenuStructureSettingScreen.negative_number'))
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable(),
        display_group: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .min(0, t('MenuStructureSettingScreen.negative_number'))
          .when('display_menu', (display_menu, schema) => {
            if (displayMenu) return schema.required(t('MenuStructureSettingScreen.warn_field_required'));
            return schema;
          })
          .nullable(),
        remarks: yup.string().nullable(),
        effective_start_date: yup.string().required(t('MenuStructureSettingScreen.warn_field_required')),
        effective_end_date: yup.string().required(t('MenuStructureSettingScreen.warn_field_required')),
        unit: yup.string().required(t('MenuStructureSettingScreen.warn_field_required')),
      })
    ),
  });

  // react-hook-form setup
  const [displayMenu, setDisplayMenu] = useState(false);
  const {
    reset,
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<MenuStructureSettingForm>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
    mode: 'all',
  });
  const { fields, append, remove } = useFieldArray({
    name: 'compositions',
    control,
    keyName: 'key',
  });

  // set API data to react-hook-form
  const selectedBusiness = watch('business');

  const fetchPurchaseUnitMasters = async () => {
    try {
      const res = await PurchaseUnitMasterService.getAll();
      setPurchaseUnitMasters(res.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const fetchElementUnitMasters = async () => {
    try {
      const res = await ElementUnitMasterService.getAll();
      setElementUnitMasters(res.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  useEffect(() => {
    fetchPurchaseUnitMasters();
    fetchElementUnitMasters();
  }, []);

  useEffect(() => {
    if (!id) return;
    if (!menuStructureData) return;
    if (purchaseUnitMasters.length <= 0) return;
    if (elementUnitMasters.length <= 0) return;

    const mappedMenuStructureData = {
      menu_code: '',
      menu_name: menuStructureData.menu_name,
      business: menuStructureData.business_format_cd,
      display_menu: menuStructureData.display_menu,
      sort_key: menuStructureData?.sort_key ?? null,
      compositions: menuStructureData.menu_composition_masters?.map((composition: any) => {
        const isElement = composition.supplier_item_cd ? false : true;
        const composition_unit_cd = composition.composition_unit_cd;
        const filteredPurchaseUnitMasters = purchaseUnitMasters.filter(
          (unit: any) =>
            unit.purchase_unit_cd === composition.purchase_item_unit_cd ||
            unit.purchase_unit_cd === composition.process_item_unit_cd ||
            unit.purchase_unit_cd === composition?.supplementary_unit_cd_1 ||
            unit.purchase_unit_cd === composition?.supplementary_unit_cd_2 ||
            unit.purchase_unit_cd === composition?.supplementary_unit_cd_3
        );
        const filteredElementUnitMasters = elementUnitMasters.filter(
          (unit: any) =>
            unit.element_unit_cd === composition.element_unit_master ||
            unit.element_unit_cd === composition?.supplementary_unit_1 ||
            unit.element_unit_cd === composition?.supplementary_unit_2
        );
        const compositionUnitCode = isElement
          ? filteredElementUnitMasters.some((unit: any) => unit.element_unit_cd === composition_unit_cd)
            ? composition_unit_cd
            : filteredElementUnitMasters[0]?.element_unit_cd
          : purchaseUnitMasters.some((unit: any) => unit.purchase_unit_cd === composition_unit_cd)
          ? composition_unit_cd
          : filteredPurchaseUnitMasters[0]?.purchase_unit_cd;

        return {
          ...composition,
          usage_amount: Number(composition.composition_quantity).toFixed(1),
          code: composition.composition_cd,
          name: composition?.purchase_item_name || composition?.process_item_name || composition?.element_name,
          unit: compositionUnitCode,
          display_group: composition.display_group_id,
        };
      }),
      suspension_date: DEFAULT_SUSPENSION_DATE,
      // sort_key: menuStructureData.sort_key,
      // menu_category: menuStructureData.menu_category_master_id,
      // breakdown_display_flg: menuStructureData.breakdown_display_flg,
    };
    reset(mappedMenuStructureData);
    // append(menuStructureData.menu_composition_masters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuStructureData, businessFormatMasterList, elementUnitMasters, purchaseUnitMasters]);

  useEffect(() => {
    return () => {
      dispatch(menuStructureSettingActions.clear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) return;
    setValue('business', businessFormatMasterList[0]?.business_format_cd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessFormatMasterList]);

  const fetchMenuCategoryMaster = async (selectedBusiness?: string) => {
    try {
      if (selectedBusiness === undefined) return;
      const res = await menuCategoryMasterService.getAll({ businessType: selectedBusiness });

      // res.data.unshift(MENU_CATEGORY_HIDEN_OPTION);
      setMenuCategoryOptions(res.data);
      setValue('menu_category', res.data[0]?.menu_category_cd);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  useEffect(() => {
    fetchMenuCategoryMaster(selectedBusiness);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness]);

  const suspensioDateValue = watch('suspension_date');

  // composition func
  const handleRemoveComposition = (index: number) => {
    remove(index);
  };

  const hanleAddComposition = (composition: any) => {
    append({
      ...composition,
      code: composition.purchase_item_cd || composition.process_item_cd || composition.element_cd,
      name: composition.purchase_item_name || composition.process_item_name || composition.element_name,
      supplementary_unit_cd_1:
        composition.purchase_supplementary_unit_cd_1 ||
        composition.process_supplementary_unit_cd_1 ||
        composition.element_supplementary_unit_1,
      supplementary_unit_cd_2:
        composition.purchase_supplementary_unit_cd_2 ||
        composition.process_supplementary_unit_cd_2 ||
        composition.element_supplementary_unit_2,
      supplementary_unit_cd_3:
        composition.purchase_supplementary_unit_cd_3 || composition.process_supplementary_unit_cd_3,
      usage_amount: '',
      effective_start_date: composition.effective_start_date ? new Date(composition.effective_start_date) : new Date(),
      effective_end_date: composition.effective_end_date
        ? new Date(composition.effective_end_date)
        : new Date(DEFAULT_EFFECTIVE_END_DATE),
    });
  };

  // modal func
  const handleAddCompositionClick = () => {
    setIsOpenAddCompositionModal(true);
  };

  const handleCloseSearchCompositionModalClick = () => {
    setIsOpenAddCompositionModal(false);
  };

  const handleCloseDetailCompositionModalClick = () => {
    setIsDetailCompositionModal(false);
  };

  const onOpenAddCompositionModal = (code: string) => {
    setChosenDetailComposition(code);
    setIsDetailCompositionModal(true);
  };

  const onSubmit = async (payload: MenuStructureSettingForm) => {
    try {
      const mappedData = {
        ...payload,
        business_format_cd: payload.business,
        menu_category_master: payload.menu_category,
        breakdown_display_flg: payload.display_menu,
        change_flg: false,
        sort_key: payload?.sort_key ?? null,
        suspend_date: dayjs(payload.suspension_date).format('YYYY-MM-DD'),
        compositions: payload?.compositions?.map((composition: MenuCompositionStructureSetting) => {
          return {
            ...composition,
            composition_cd: composition.code,
            composition_quantity: composition.usage_amount,
            composition_unit_cd: composition.unit,
            effective_start_date: dayjs(composition.effective_start_date).format('YYYY-MM-DD'),
            effective_end_date: dayjs(composition.effective_end_date).format('YYYY-MM-DD'),
            display_group_id: composition.display_group ?? null,
            sort_key: composition.sort_key ?? null,
            change_flg: false,
          };
        }),
      };
      const res = await MenuStructureSettingService.add(mappedData);
      toast.success(res.message);
      const newMenuCode = res.data.menu_cd;
      history.push(`/calorie-management/menus-structure-setting/edit/${newMenuCode}`);
    } catch (error: any) {
      const message = error.response.data.message;
      toast.error(message);
      console.error('Error fetching data: ', error);
    }
  };

  const handleRegister = (event: MouseEvent<HTMLElement>) => {
    return handleSubmit((data) => onSubmit(data))(event);
  };

  // render func
  return (
    <Form className="page-content d-flex flex-column">
      {isOpenAddCompositionModal && (
        <SearchCompositionModal
          onClose={handleCloseSearchCompositionModalClick}
          onClickAddComposition={hanleAddComposition}
          selectedCompositions={fields.map((composition) => composition.code)}
        />
      )}
      {isDetailCompositionModal && (
        <ViewDetailProductModal
          onClose={handleCloseDetailCompositionModalClick}
          chosenDetailComposition={chosenDetailComposition}
        />
      )}

      {/* Header */}
      <div className="pb-3 border-bottom">
        <Row>
          <Col xs="4">
            <h4 className="mb-0">{t('MenuStructureSettingScreen.create_head_title')}</h4>
          </Col>
          <Col xs="8" className="d-flex justify-content-end">
            <Stack direction="horizontal" gap={4}>
              <Button onClick={handleRegister}>{t('MenuStructureSettingScreen.register')}</Button>
              <Button onClick={() => history.push(`/calorie-management/menus-structure-setting`)}>
                {t('MenuStructureSettingScreen.back')}
              </Button>
            </Stack>
          </Col>
        </Row>
      </div>

      {/* Form field */}
      <div className="py-3">
        <div style={{ maxWidth: '700px' }}>
          <Form.Group as={Row} className="mb-3" controlId="textbox">
            <Form.Label column sm="3" className="text-start">
              {t('MenuStructureSettingScreen.menu')}
            </Form.Label>
            <Col sm="8">
              <Form.Control {...register('menu_code')} type="text" disabled />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="combobox">
            <Form.Label column sm="3" className="text-start">
              {t('MenuStructureSettingScreen.business')}
            </Form.Label>
            <Col sm="8">
              <Form.Select
                isInvalid={Boolean(errors?.business)}
                style={{ width: '200px' }}
                {...register(`business`)}
                disabled={!isAllowedEdit}
              >
                {businessFormatMasterList.map((option: any, idx: number) => {
                  return (
                    <option key={idx} value={option.business_format_cd} hidden={option?.hidden}>
                      {option.name}
                    </option>
                  );
                })}
              </Form.Select>
              {errors?.business && (
                <span className="small text-danger d-inline-block mt-1">{errors?.business?.message}</span>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="textbox">
            <Form.Label column sm="3" className="text-start">
              {t('MenuStructureSettingScreen.name')}
            </Form.Label>
            <Col sm="8">
              <Form.Control
                {...register('menu_name')}
                type="text"
                isInvalid={Boolean(errors?.menu_name?.message)}
                disabled={!isAllowedEdit}
              />
              {errors?.menu_name && (
                <span className="small text-danger d-inline-block mt-1">{errors?.menu_name?.message}</span>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="combobox">
            <Form.Label column sm="3" className="text-start">
              {t('MenuStructureSettingScreen.menu_category')}
            </Form.Label>
            <Col sm="8">
              <Form.Select
                isInvalid={Boolean(errors?.menu_category)}
                style={{ width: '200px' }}
                {...register(`menu_category`)}
                disabled={!isAllowedEdit}
              >
                {menuCategoryOptions.map((option: any, idx: number) => {
                  return (
                    <option key={idx} value={option.menu_category_cd} hidden={option?.hidden}>
                      {option.menu_category_name}
                    </option>
                  );
                })}
              </Form.Select>
              {errors?.menu_category && (
                <span className="small text-danger d-inline-block mt-1">{errors?.menu_category?.message}</span>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="textnumeric">
            <Form.Label column sm="3" className="text-start">
              {t('MenuStructureSettingScreen.suspension_date')}
            </Form.Label>
            <Col sm="8">
              <div style={{ maxWidth: '120px' }}>
                <DatePickerCustom
                  value={suspensioDateValue || DEFAULT_SUSPENSION_DATE}
                  onChange={(date: Date) => {
                    setValue('suspension_date', date);
                  }}
                  disabled={!isAllowedEdit}
                />
              </div>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="textnumeric">
            <Form.Label column sm="3" className="text-start">
              {t('MenuStructureSettingScreen.sort_key')}
            </Form.Label>
            <Col sm="8">
              <Form.Control
                {...register('sort_key')}
                type="number"
                isInvalid={Boolean(errors?.sort_key?.message)}
                disabled={!isAllowedEdit}
                onKeyDown={(evt) =>
                  (evt.key === 'e' || evt.key === '-' || evt.key === 'E' || evt.key === '+') && evt.preventDefault()
                }
              />
              {errors?.sort_key && (
                <span className="small text-danger d-inline-block mt-1">{errors?.sort_key?.message}</span>
              )}
            </Col>
          </Form.Group>
        </div>
      </div>

      {/* Table filter */}
      <div className="d-flex justify-content-between py-2">
        <Form.Label column sm="4" className="text-start">
          {t('MenuStructureSettingScreen.composition')}
        </Form.Label>
        <div style={{ display: 'flex' }}>
          <Stack direction="horizontal" gap={4}>
            <Button onClick={handleAddCompositionClick} disabled={!isAllowedEdit}>
              {t('MenuStructureSettingScreen.additional')}
            </Button>
          </Stack>
        </div>
      </div>

      <CreateTable
        elementUnitMasters={elementUnitMasters}
        purchaseUnitMasters={purchaseUnitMasters}
        compositions={fields}
        disabled={!isAllowedEdit}
        register={register}
        errors={errors}
        onOpenAddCompositionModal={onOpenAddCompositionModal}
        onRemoveComposition={handleRemoveComposition}
        setValue={setValue}
        watch={watch}
      />

      <Form.Check
        className="anra-checkbox"
        style={{ marginTop: 5 }}
        type="checkbox"
        {...register('display_menu')}
        inline
        label={t('MenuStructureSettingScreen.display_menu')}
        onChange={(e) => setDisplayMenu(e.target.checked)}
        disabled={!isAllowedEdit}
      />
    </Form>
  );
};

export default MenuStructureSettingCreate;
