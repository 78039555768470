import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { errorNotificationReducer } from 'components/common/ErrorNotification/errorNotificationSlice';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { authActions, authReducer } from 'features/Auth/authSlice';
import { PERSIST, persistStore, PURGE, REHYDRATE } from 'redux-persist';
import persistReducer from 'redux-persist/es/persistReducer';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { Config, createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync';
import { allergyItemMasterReducer } from 'store/AllergyItemMaster/allergyItemMasterSlice';
import { allergyMasterReducer } from 'store/AllergyMaster/allergyMasterSlice';
import { appReducer } from 'store/appSlice';
import { businessFormatMasterReducer } from 'store/BusinessFormatMaster/businessFormatMasterSlice';
import { elementClassificationMasterReducer } from 'store/ElementClassificationMaster/elementClassificationMasterSlice';
import { menuCategoryMasterReducer } from 'store/MenuCategoryMaster/menuCategoryMasterSlice';
import { nutritionMasterReducer } from 'store/NutritionMaster/nutritionMasterSlice';
import { nutritionUnitMasterReducer } from 'store/NutritionUnitMaster/nutritionUnitMasterSlice';
import { history } from 'utils';
import rootSaga from './rootSaga';
import { menuStructureSettingReducer } from 'features/CalorieManagement/MenuStructureSetting/screens/menuStructureSettingSlice';
import {
  draftChartActions,
  draftChartReducer,
} from 'features/CalorieManagement/CalorieAllergySetting/screens/CalorieAllergyEdit/CalorieAllergyEditSlice';
import { partsConfigurationReducer } from 'features/CalorieManagement/PartsConfigurationSetting/Slice';

const rootReducer = combineReducers({
  router: connectRouter(history),
  app: appReducer,
  auth: authReducer,
  errorNotification: errorNotificationReducer,
  menuStructureSetting: menuStructureSettingReducer,
  allergyMaster: allergyMasterReducer,
  allergyItemMaster: allergyItemMasterReducer,
  nutritionUnitMaster: nutritionUnitMasterReducer,
  elementClassificationMaster: elementClassificationMasterReducer,
  nutritionMaster: nutritionMasterReducer,
  businessFormatMaster: businessFormatMasterReducer,
  menuCategoryMaster: menuCategoryMasterReducer,
  draftChart: draftChartReducer,
  partsConfiguration: partsConfigurationReducer,
});

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth', 'draftChart'],
  stateReconciler: autoMergeLevel2,
};

const reduxStateSyncConfig: Config = {
  channel: 'acm_broadcast_channel',
  whitelist: [
    authActions.actionLogin.type,
    authActions.actionSetSelectedJob.type,
    authActions.actionTokenExpired.type,
    authActions.actionLogout.type,
    draftChartActions.addDraftChart.type,
    draftChartActions.removeDraftChart.type,
    draftChartActions.clearAllDraftChart.type,
  ],
  blacklist: [PERSIST, PURGE, REHYDRATE],
  broadcastChannelOption: { type: 'localstorage' },
  predicate: (action) => {
    if (typeof action !== 'function') {
      const actionType = action.type;
      const isWhitelisted = [
        authActions.actionLogin.type,
        authActions.actionSetSelectedJob.type,
        authActions.actionTokenExpired.type,
        authActions.actionLogout.type,
        draftChartActions.addDraftChart.type,
        draftChartActions.removeDraftChart.type,
        draftChartActions.clearAllDraftChart.type,
      ].includes(actionType);
      const isBlacklisted = [PERSIST, PURGE, REHYDRATE].includes(actionType);
      if (isWhitelisted) {
        return true;
      }
      if (isBlacklisted) {
        return false;
      }
    }
    return false;
  },
};

const persistedReducer = persistReducer<ReturnType<typeof rootReducer>>(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
      immutableCheck: true,
    }).concat(sagaMiddleware, routerMiddleware(history), createStateSyncMiddleware(reduxStateSyncConfig)),
});

// this is used to pass store.dispatch to the message listener
// init state with other tabs
initStateWithPrevTab(store);

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export const persistor = persistStore(store);
