import { GROUP_HEADER } from 'features/PDF/constants/group-header';
import { ColTableStyleByTypeInterFace, PDF_COL_STYLE_TABLE_BY_TYPE } from '../../constants/style';
import { groupBy } from 'utils/group-by';

export interface GroupHeaderValueTypeInterface {
  title: string;
  group: string;
  style: {
    backgroundColor: string;
    width?: number;
  };
  subHeader?: {
    title: string;
    style: ColTableStyleByTypeInterFace[keyof ColTableStyleByTypeInterFace];
  }[];
}

export interface HeaderValueTypeInterface {
  title: string;
  group: string;
  style: ColTableStyleByTypeInterFace[keyof ColTableStyleByTypeInterFace];
}

const otherColWidthCal = (cols: any, totalWidth: any = 100): number => {
  const colNumber = cols.length;
  let totalSepecialColWidth = 0;
  let totalNumberOfSpecialCol = 0;

  cols.forEach((col: any) => {
    if (col.style.width) {
      totalNumberOfSpecialCol += 1;
      totalSepecialColWidth += col.style.width;
    }
  });

  const otherWidth = (totalWidth - totalSepecialColWidth) / (colNumber - totalNumberOfSpecialCol);
  return otherWidth;
};

const mapGroupHeaderStyleToHeader = (
  headers: HeaderValueTypeInterface[],
  headerGroups = GROUP_HEADER,
  headerWidth: any = null
) => {
  return headers.map((header, index) => {
    const groupedHeaderGroup = groupBy(headerGroups, ({ group }: any) => group) as any;
    const otherWidth = otherColWidthCal(headers, headerWidth ?? remainWidthAfterSelection());

    return {
      ...header,
      style: {
        ...header.style,
        backgroundColor: groupedHeaderGroup[header.group][0]['style']['backgroundColor'],
        width: header.style.width ?? otherWidth,
      },
    };
  });
};

const genWidthGroupHeader = (
  headers: HeaderValueTypeInterface[],
  headerGroups = GROUP_HEADER,
  headerWidth: any = null
) => {
  const mappedHeaders = mapGroupHeaderStyleToHeader(headers, headerGroups, headerWidth);

  return headerGroups.map((headerGroup) => {
    let total = 0;

    mappedHeaders.forEach((header) => {
      if (header.group === headerGroup.group) {
        total += header.style.width;
      }
    });
    return {
      ...headerGroup,
      style: {
        ...headerGroup.style,
        width: total,
      },
    };
  });
};

const remainWidthAfterSelection = () => {
  return 100 - (PDF_COL_STYLE_TABLE_BY_TYPE['select']['width'] || 0) * 2;
};

export { mapGroupHeaderStyleToHeader, genWidthGroupHeader, otherColWidthCal, remainWidthAfterSelection };
