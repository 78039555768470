import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { PaginationResponse } from 'models';
import { ElementConfigurationSettingList } from './models/model';
import { PENDING_APPROVAL, PENDING_CONFIRMATION, TEMPORARY_SAVE } from './constant';

//type
export type StatusType = 'all' | typeof PENDING_CONFIRMATION | typeof PENDING_APPROVAL | typeof TEMPORARY_SAVE;
export type PeriodType = 'all' | 'valid' | 'invalid';
export type NotificationType = 'all' | 'changed' | 'notChanged';

export interface SearchFiltersState {
  search: string;
  classification: string;
  period: PeriodType;
  status: StatusType;
  notification: NotificationType;
}

//auth state class
export interface PartsConfigurationState {
  loading: boolean;
  response: PaginationResponse<ElementConfigurationSettingList> | undefined;
  filters: SearchFiltersState;
}

// init model state
const initialState: PartsConfigurationState = {
  loading: false,
  response: undefined,
  filters: {
    search: '',
    classification: 'all',
    period: 'all',
    status: 'all',
    notification: 'all',
  },
};

// slice parts configuration
const partsConfigurationSlice = createSlice({
  name: 'partsConfiguration',
  initialState,
  reducers: {
    fetchPartsConfiguration(state, action: PayloadAction<string>) {
      state.loading = true;
    },
    fetchPartsConfigurationSuccess(state, action: PayloadAction<PaginationResponse<ElementConfigurationSettingList>>) {
      state.response = action.payload;
      state.loading = false;
    },
    fetchPartsConfigurationFailed(state) {
      state.loading = false;
    },
    updatePartsConfiguration(state, action: PayloadAction<ElementConfigurationSettingList>) {
      if (state.response) {
        state.response = {
          ...state.response,
          results: state.response.results.map((item) =>
            item.element_cd === action.payload.element_cd ? action.payload : item
          ),
        };
      }
    },
    setFilters(state, action: PayloadAction<SearchFiltersState>) {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    clearFilters(state) {
      state.filters = {
        search: '',
        classification: 'all',
        period: 'all',
        status: 'all',
        notification: 'all',
      };
    },
  },
});

// Actions
export const partsConfigurationActions = partsConfigurationSlice.actions;

// Selectors
export const selectPartsConfiguration = (state: RootState) => state.partsConfiguration.response;
export const selectFilters = (state: RootState) => state.partsConfiguration.filters;

// Reducer
export const partsConfigurationReducer = partsConfigurationSlice.reducer;
