import { Button, Col, Form, FormControl, InputGroup, Row, Stack } from 'react-bootstrap';
import { TfiAngleLeft, TfiAngleRight } from 'react-icons/tfi';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../Service';
import { NutritionMaster, PurchaseCalorieSetting } from '../models/PurchaseProcessedCalorieAllergySetting';
import {
  ITEMS_EQUIVALENT_TO_SPECIFIC_RAW_MATERIALS,
  SPECIFIC_RAW_MATERIALS,
  PENDING_CONFIRMATION,
  PENDING_APPROVAL,
} from '../constant';
import { toast } from 'react-toastify';
import { store } from 'app/store';
import { appActions } from 'store/appSlice';
import generateToastContent, { ToastContentItem } from '../components/GenerateToastContent';
import ConfirmationDialog from '../components/ConfirmationDialog';

const COLUMN_WIDTH = [
  '3.125rem',
  '6.375rem',
  '6rem',
  '8.25rem',
  '14.625rem',
  '7.313rem',
  '19.625rem',
  '21.875rem',
  '8.875rem',
  '8.25rem',
  '8.875rem',
  '8.25rem',
];

function PurchaseProcessedCalorieAllergySettingList() {
  const { t } = useTranslation();
  const history = useHistory();
  const { dispatch } = store;
  //state
  const [data, setData] = useState<Array<PurchaseCalorieSetting>>([]);
  const [data2, setData2] = useState<Array<PurchaseCalorieSetting>>([]);
  const [nutritions, setNutritions] = useState<NutritionMaster[]>([]);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  type StatusType = 'all' | typeof PENDING_CONFIRMATION | typeof PENDING_APPROVAL;

  //ref common
  const searchRef = useRef<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  //ref table 1
  const nextPageRef = useRef<string | null>(null);
  const prevPageRef = useRef<string | null>(null);
  const pageRef = useRef<number>(1);
  const totalPageRef = useRef<number>(0);
  const pageCurrentElement = useRef<HTMLInputElement>(null);
  const selectedCalorieRef = useRef<string[]>([]);
  const checkBoxRefs = useRef<HTMLInputElement[]>([]);

  //ref table 2
  const statusRef = useRef<StatusType>('all');
  const showAllPurchaseRef = useRef<boolean>(false);
  const nextPage2Ref = useRef<string | null>(null);
  const prevPage2Ref = useRef<string | null>(null);
  const page2Ref = useRef<number>(1);
  const totalPage2Ref = useRef<number>(0);
  const page2CurrentElement = useRef<HTMLInputElement>(null);
  const selectedCalorie2Ref = useRef<string[]>([]);
  const checkBox2Refs = useRef<HTMLInputElement[]>([]);
  const hiddenMessagesRef = useRef<JSX.Element[]>([]);

  //useEffect

  useEffect(() => {
    fetchData('/calories-settings-masters');
    fetchData2('/related-menu-calories-settings-masters');
    fetchNutritions();
  }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      resetCheckBox(checkBoxRefs, selectedCalorieRef);
    }
    selectedCalorieRef.current = [];
  }, [data]);

  useEffect(() => {
    if (data2 && data2.length > 0) {
      resetCheckBox(checkBox2Refs, selectedCalorie2Ref);
    }
    selectedCalorie2Ref.current = [];
  }, [data2]);

  const resetCheckBox = (
    checkBoxRefs: React.MutableRefObject<HTMLInputElement[]>,
    selectedCalorieRef: React.MutableRefObject<string[]>
  ) => {
    if (checkBoxRefs.current.length > 0 && selectedCalorieRef.current.length > 0) {
      checkBoxRefs.current.forEach((checkBox) => {
        if (checkBox) {
          checkBox.checked = false;
          (checkBox.parentElement?.parentElement?.parentElement as HTMLElement).classList.toggle(
            'div-tr-selected',
            false
          );
        }
      });
      checkBoxRefs.current = [];
      // selectedCalorieRef.current = [];
    }
  };

  const unCheckedAll = (
    selectedCalorieRef: React.MutableRefObject<string[]>,
    checkBoxRefs: React.MutableRefObject<HTMLInputElement[]>
  ) => {
    selectedCalorieRef.current = [];
    checkBoxRefs.current.forEach((checkBox) => {
      if (checkBox) {
        checkBox.checked = false;
        (checkBox.parentElement?.parentElement?.parentElement as HTMLElement).classList.toggle(
          'div-tr-selected',
          false
        );
      }
    });
  };

  // function
  const fetchNutritions = async () => {
    try {
      const res = await api.getAllNutritions();
      setNutritions(res);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  //handler
  const handleClickSearch = () => {
    fetchData(`/calories-settings-masters?page=1&search=${searchRef.current}&status=${statusRef.current}`);
    fetchData2(
      `/related-menu-calories-settings-masters?page=1&search=${searchRef.current}&status=${
        statusRef.current
      }&show_all_purchase=${showAllPurchaseRef.current ? 1 : 0}`
    );
  };

  const handleInputSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    searchRef.current = event.target.value;
    pageRef.current = 1;
    page2Ref.current = 1;
  };

  // area table 1
  const fetchData = async (url: string) => {
    try {
      const response = await api.getAll(url);
      const data = response.results;
      setData(data);
      totalPageRef.current = Math.ceil(response.count / response.page_size);
      if (pageCurrentElement.current) {
        pageRef.current = response.page_current;
        pageCurrentElement.current.value = pageRef.current.toString();
      }
      nextPageRef.current = response.next;
      prevPageRef.current = response.previous;
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const handleNextPage = () => {
    if (nextPageRef.current) {
      const url = new URL(nextPageRef.current);
      const page = url.searchParams.get('page');
      fetchData(
        `/calories-settings-masters?page=${page || '1'}&search=${searchRef.current}&status=${statusRef.current}`
      );
    }
  };

  const handlePrevPage = () => {
    if (prevPageRef.current) {
      const url = new URL(prevPageRef.current);
      const page = url.searchParams.get('page');
      fetchData(
        `/calories-settings-masters?page=${page || '1'}&search=${searchRef.current}&status=${statusRef.current}`
      );
    }
  };

  const handleEnterToPage = () => {
    const currentPage = pageCurrentElement.current?.value;
    if (currentPage && !isNaN(parseInt(currentPage))) {
      const pageNumber = parseInt(currentPage);
      if (pageNumber >= 1 && pageNumber <= totalPageRef.current) {
        fetchData(
          `/calories-settings-masters?page=${pageNumber}&search=${searchRef.current}&status=${statusRef.current}`
        );
        pageRef.current = pageNumber;
      }
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    const index = selectedCalorieRef.current.indexOf(value);
    if (checked && index === -1) {
      selectedCalorieRef.current.push(value);
    } else {
      selectedCalorieRef.current.splice(index, 1);
    }
    (event.target.parentElement?.parentElement?.parentElement as HTMLElement).classList.toggle(
      'div-tr-selected',
      checked
    );
    //reset checkbox table 2
    unCheckedAll(selectedCalorie2Ref, checkBox2Refs);
  };

  const handleHiddenPurchasedProcessedItem = async () => {
    if (selectedCalorieRef.current.length > 0) {
      const sms_cds = selectedCalorieRef.current.join(',');
      try {
        const response = await api.patchByIds({ sms_cds: sms_cds });
        //selectedCalorieRef.current = [];
        toast.success(response.message);
        fetchData('/calories-settings-masters?page=1&search=' + searchRef.current + '&status=' + statusRef.current);
        fetchData2(
          `/related-menu-calories-settings-masters?page=1&search=${searchRef.current}&status=${
            statusRef.current
          }&show_all_purchase=${showAllPurchaseRef.current ? 1 : 0}`
        );
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    }
    setShowConfirmationDialog(false);
  };

  // area table 2
  const handleInputStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === PENDING_APPROVAL || value === PENDING_CONFIRMATION) {
      statusRef.current = value;
    } else {
      statusRef.current = 'all';
    }
    fetchData(`/calories-settings-masters?page=1&search=${searchRef.current}&status=${statusRef.current}`);
    fetchData2(
      `/related-menu-calories-settings-masters?page=1&search=${searchRef.current}&status=${
        statusRef.current
      }&show_all_purchase=${showAllPurchaseRef.current ? 1 : 0}`
    );
  };

  const handleCheckboxShowAllPurchase = (event: React.ChangeEvent<HTMLInputElement>) => {
    showAllPurchaseRef.current = event.target.checked;
    fetchData2(
      `/related-menu-calories-settings-masters?page=1&search=${searchRef.current}&status=${
        statusRef.current
      }&show_all_purchase=${showAllPurchaseRef.current ? '1' : '0'}`
    );
  };

  const fetchData2 = async (url: string) => {
    try {
      const response = await api.getAll(url);
      const data = response.results;
      setData2(data);
      totalPage2Ref.current = Math.ceil(response.count / response.page_size);
      if (page2CurrentElement.current) {
        page2Ref.current = response.page_current;
        page2CurrentElement.current.value = page2Ref.current.toString();
      }
      nextPage2Ref.current = response.next;
      prevPage2Ref.current = response.previous;
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const handleNextPage2 = () => {
    if (nextPage2Ref.current) {
      const url = new URL(nextPage2Ref.current);
      const page = url.searchParams.get('page');
      fetchData2(
        `/related-menu-calories-settings-masters?page=${page || '1'}&search=${searchRef.current}&status=${
          statusRef.current
        }&show_all_purchase=${showAllPurchaseRef.current ? 1 : 0}`
      );
    }
  };

  const handlePrevPage2 = () => {
    if (prevPage2Ref.current) {
      const url = new URL(prevPage2Ref.current);
      const page = url.searchParams.get('page');
      fetchData2(
        `/related-menu-calories-settings-masters?page=${page || '1'}&search=${searchRef.current}&status=${
          statusRef.current
        }&show_all_purchase=${showAllPurchaseRef.current ? 1 : 0}`
      );
    }
  };

  const handleEnterToPage2 = () => {
    const currentPage = page2CurrentElement.current?.value;
    if (currentPage && !isNaN(parseInt(currentPage))) {
      const pageNumber = parseInt(currentPage);
      if (pageNumber >= 1 && pageNumber <= totalPage2Ref.current) {
        fetchData2(
          `/related-menu-calories-settings-masters?page=${pageNumber}&search=${searchRef.current}&status=${
            statusRef.current
          }&show_all_purchase=${showAllPurchaseRef.current ? 1 : 0}`
        );
        page2Ref.current = pageNumber;
      }
    }
  };

  const handleCheckboxChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    const index = selectedCalorie2Ref.current.indexOf(value);
    if (checked && index === -1) {
      selectedCalorie2Ref.current.push(value);
    } else {
      selectedCalorie2Ref.current.splice(index, 1);
    }
    (event.target.parentElement?.parentElement?.parentElement as HTMLElement).classList.toggle(
      'div-tr-selected',
      checked
    );
    //reset checkbox table 1
    unCheckedAll(selectedCalorieRef, checkBoxRefs);
  };

  const handleOpenEditPage = () => {
    if (selectedCalorieRef.current.length !== 1 && selectedCalorie2Ref.current.length !== 1) {
      return;
    }
    const sms_cd = selectedCalorieRef.current[0];
    const sms_cd2 = selectedCalorie2Ref.current[0];
    history.push(`/calorie-management/purchase-processed-calorie-allergy-setting/${sms_cd || sms_cd2}`);
  };

  const handleClickMasterDataIntegration = () => {
    dispatch(appActions.showAppLoading());
    const apiCallsMasterDataIntegration = [
      api.getMasterDataIntegrationElementUnitMaster,
      api.getMasterDataIntegrationPurchaseUnitMaster,
      api.getMasterDataIntegrationPurchaseMaster,
      api.getMasterDataIntegrationProcessMaster,
      api.getMasterDataIntegrationBusinessFormatMaster,
      api.getMasterDataIntegrationSnapshotCompanies,
    ];
    const listContent: ToastContentItem[] = [];
    Promise.allSettled(apiCallsMasterDataIntegration.map((apiCall) => apiCall())).then((results) => {
      results.forEach((result) => {
        if (result.status === 'fulfilled') {
          if (result?.value?.message.length > 0) {
            listContent.push({ message: result.value.message, success: true });
          } else {
            listContent.push({ message: t('App.unexpected_error'), success: false });
          }
        } else {
          if (result.reason.response?.status === 404 || !result.reason.response) {
            listContent.push({ message: t('App.unexpected_error'), success: false });
          } else {
            listContent.push({ message: result.reason.response?.data.message, success: false });
          }
        }
      });
      fetchData('/calories-settings-masters');
      fetchData2('/related-menu-calories-settings-masters');
      if (inputRef.current) {
        inputRef.current.value = '';
      }
      const toastContent = generateToastContent(listContent, 'マスターデータ連携');
      toast(toastContent, {
        autoClose: false,
        theme: 'light',
        closeOnClick: false,
      });
      dispatch(appActions.hideAppLoading());
    });
  };

  const handleConfirmHiddenProcessedCalorieAllergyInfo = () => {
    if (selectedCalorieRef.current.length > 0) {
      const purchaseItems = data.filter((element) => selectedCalorieRef.current.includes(element.supplier_item_cd));
      const purchaseCds = purchaseItems.map((purchase) => (
        <div key={purchase.supplier_item_cd} className="row">
          <div className="col">
            <span className="fw-bold">
              {purchase.process_item?.process_item_cd || purchase.purchase_item.purchase_item_cd}
            </span>
            &nbsp;&nbsp;
            {purchase.process_item?.process_item_name || purchase.purchase_item.purchase_item_name}
          </div>
        </div>
      ));
      hiddenMessagesRef.current = [
        <div key="message" className="row">
          <div className="col fw-bold pb-2">{t('CalorieAllergySetting.DialogConfirm.message')}</div>
        </div>,
        ...purchaseCds,
      ];
      setShowConfirmationDialog(true);
    }
  };

  return (
    <>
      <div className="page-content d-flex flex-column">
        {/* header */}
        <div className="pb-2 border-bottom">
          <Row className="align-items-center">
            <Col xs="4">
              <h4 className="mb-0 fw-bold">{t('CalorieAllergySetting.head_title')}</h4>
            </Col>
            <Col xs="8" className="d-flex justify-content-end">
              <Stack direction="horizontal" gap={4}>
                <Button className="fs-5 fw-bold btn-sm" onClick={handleConfirmHiddenProcessedCalorieAllergyInfo}>
                  {t('CalorieAllergySetting.hidden')}
                </Button>
                <Button className="fs-5 fw-bold text-muted btn-sm" variant="secondary" onClick={handleOpenEditPage}>
                  {t('CalorieAllergySetting.edit')}
                </Button>
                <Button className="fs-5 fw-bold btn-sm" onClick={handleClickMasterDataIntegration}>
                  {t('CalorieAllergySetting.master_data_integration')}
                </Button>
                {/* <Button className="fs-5 fw-bold" onClick={() => history.goBack()}>
                {t('CalorieAllergySetting.go_back')}
              </Button> */}
              </Stack>
            </Col>
          </Row>
        </div>

        {/* filter */}
        <div className="py-1">
          <Row className="align-items-center gap-4 mb-1 mb-xs-0">
            <Col xs="auto" sm="5" md="2">
              <InputGroup>
                <FormControl
                  className="border-end-0"
                  placeholder={t('CalorieAllergySetting.name_or_code_search')}
                  aria-label="Search"
                  onChange={handleInputSearch}
                  ref={inputRef}
                />
                <InputGroup.Text className="bg-transparent border-start-0 cursor-pointer" onClick={handleClickSearch}>
                  <FaSearch />
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
          <Row className="align-items-center gap-4">
            <Col xs="auto">{t('CalorieAllergySetting.status')}</Col>
            <Col xs="auto">
              <Row className="align-items-center">
                <Col xs="auto">
                  <Form.Check
                    className="anra-checkbox"
                    inline
                    label="全て"
                    name="group_status"
                    type="radio"
                    defaultChecked
                    id="group_status-all"
                    onChange={handleInputStatus}
                    defaultValue={'all'}
                  />
                  <Form.Check
                    className="anra-checkbox"
                    inline
                    label={t('CalorieAllergySetting.pending_confirmation')}
                    name="group_status"
                    type="radio"
                    id="group_status-pending_confirmation"
                    onChange={handleInputStatus}
                    defaultValue={PENDING_CONFIRMATION}
                  />
                  <Form.Check
                    className="anra-checkbox"
                    inline
                    label={t('CalorieAllergySetting.pending_approval')}
                    name="group_status"
                    type="radio"
                    id="group_status-pending_approval"
                    onChange={handleInputStatus}
                    defaultValue={PENDING_APPROVAL}
                  />
                  <Form.Check
                    className="anra-checkbox"
                    inline
                    label={t('CalorieAllergySetting.display_all_items_in_the_purchase_master')}
                    name="group_display"
                    type="checkbox"
                    id="group_display-all"
                    onChange={handleCheckboxShowAllPurchase}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        {/* <!-- Table new status--> */}
        {/* Pagination */}
        <div className="py-1">
          <div className="anra-pagination d-inline-flex flex-row gap-1 align-items-center">
            <Button size="sm" variant="primary" className="btn--auto me-1" onClick={handlePrevPage}>
              <TfiAngleLeft size={16} />
            </Button>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleEnterToPage();
              }}
            >
              <Form.Control
                size="sm"
                type="text"
                ref={pageCurrentElement}
                defaultValue={pageRef.current}
                className="d-inline-block text-center"
                style={{ width: '5rem' }}
                disabled
              />
            </Form>
            <span>/{totalPageRef.current}ページ</span>
            <Button size="sm" variant="primary" className="btn--auto ms-1" onClick={handleNextPage}>
              <TfiAngleRight size={16} />
            </Button>
            <div className="text-start ms-3 text-primary fw-bold lh-1">
              {t('CalorieAllergySetting.note_1')}
              <br />
              {t('CalorieAllergySetting.note_2')}
            </div>
          </div>
        </div>

        {/* list */}
        <div className="sticky-table">
          <div className="div-table table-status-new">
            <div className="div-thead div-tr">
              <div
                className="div-th d-flex align-items-center justify-content-center"
                style={{ width: `${COLUMN_WIDTH[0]}` }}
              >
                {t('CalorieAllergySetting.table.selection')}
              </div>
              <div
                className="div-th d-flex align-items-center justify-content-center"
                style={{ width: `${COLUMN_WIDTH[1]}` }}
              >
                {t('CalorieAllergySetting.table.status')}
              </div>
              <div
                className="div-th d-flex align-items-center justify-content-center"
                style={{ width: `${COLUMN_WIDTH[2]}` }}
              >
                {t('CalorieAllergySetting.table.sms_code')}
              </div>
              <div
                className="div-th d-flex align-items-center justify-content-center lh-1"
                style={{ width: `${COLUMN_WIDTH[3]}` }}
              >
                <span dangerouslySetInnerHTML={{ __html: t('CalorieAllergySetting.table.element_code') }} />
              </div>
              <div
                className="div-th d-flex align-items-center justify-content-center"
                style={{ width: `${COLUMN_WIDTH[4]}` }}
              >
                {t('CalorieAllergySetting.table.name')}
              </div>
              {nutritions &&
                nutritions.map((nutrition, idx) => {
                  return (
                    <div
                      className="div-th d-flex align-items-center justify-content-center lh-1"
                      style={{ width: `${COLUMN_WIDTH[5]}` }}
                      key={idx}
                    >
                      {nutrition.nutrition_name} <br></br> ({nutrition.nutrition_unit_name})
                    </div>
                  );
                })}
              <div
                className="div-th d-flex align-items-center justify-content-center"
                style={{ width: `${COLUMN_WIDTH[6]}` }}
              >
                {t('CalorieAllergySetting.table.specific_raw_materials')}
              </div>
              <div
                className="div-th d-flex align-items-center justify-content-center"
                style={{ width: `${COLUMN_WIDTH[7]}` }}
              >
                {t('CalorieAllergySetting.table.items_equivalent_to_specific_raw_materials')}
              </div>
              <div
                className="div-th d-flex align-items-center justify-content-center"
                style={{ width: `${COLUMN_WIDTH[8]}` }}
              >
                {t('CalorieAllergySetting.table.last_update_by')}
              </div>
              <div
                className="div-th d-flex align-items-center justify-content-center"
                style={{ width: `${COLUMN_WIDTH[9]}` }}
              >
                {t('CalorieAllergySetting.table.last_update_date')}
              </div>
              <div
                className="div-th d-flex align-items-center justify-content-center"
                style={{ width: `${COLUMN_WIDTH[10]}` }}
              >
                {t('CalorieAllergySetting.table.confirmed_by')}
              </div>
              <div
                className="div-th d-flex align-items-center justify-content-center"
                style={{ width: `${COLUMN_WIDTH[11]}` }}
              >
                {t('CalorieAllergySetting.table.confirmed_date')}
              </div>
            </div>

            {data &&
              data.map((purchase: PurchaseCalorieSetting, idx) => {
                return (
                  <div className="div-tr" key={idx}>
                    <div
                      className="div-td bg-yellow d-flex align-items-center justify-content-center"
                      style={{ width: `${COLUMN_WIDTH[0]}` }}
                    >
                      <Form.Check
                        className="anra-checkbox d-flex justify-content-center"
                        value={purchase.supplier_item_cd.toString()}
                        label=""
                        type="checkbox"
                        onChange={handleCheckboxChange}
                        defaultChecked={false}
                        ref={(element: HTMLInputElement) => {
                          checkBoxRefs.current[idx] = element;
                        }}
                      />
                    </div>
                    <div
                      className="div-td bg-yellow d-flex align-items-center justify-content-center"
                      style={{ width: `${COLUMN_WIDTH[1]}` }}
                    >
                      {purchase.status}
                    </div>
                    <div
                      className="div-td bg-yellow d-flex align-items-center justify-content-center"
                      style={{ width: `${COLUMN_WIDTH[2]}` }}
                    >
                      {purchase.supplier_item_cd}
                    </div>
                    <div
                      className="div-td bg-yellow d-flex align-items-center justify-content-center"
                      style={{ width: `${COLUMN_WIDTH[3]}` }}
                    >
                      {purchase.process_item
                        ? purchase.process_item.process_item_cd
                        : purchase.purchase_item.purchase_item_cd}
                    </div>
                    <div
                      className="div-td bg-yellow d-flex align-items-center justify-content-start"
                      style={{ width: `${COLUMN_WIDTH[4]}` }}
                    >
                      &nbsp;
                      {purchase.process_item
                        ? purchase.process_item.process_item_name
                        : purchase.purchase_item.purchase_item_name}
                    </div>
                    {nutritions &&
                      nutritions.map((nutrition, idx) => {
                        const item = purchase.purchase_nutrition_master.find(
                          (item) => item.nutrition_cd === nutrition.nutrition_cd
                        );
                        return (
                          <div
                            className="div-td bg-yellow d-flex align-items-center justify-content-start"
                            style={{ width: `${COLUMN_WIDTH[5]}` }}
                            key={idx}
                          >
                            &nbsp;{item?.nutrition_amount !== undefined ? item.nutrition_amount : ''}
                          </div>
                        );
                      })}
                    <div
                      className="div-td bg-yellow d-flex align-items-center justify-content-center"
                      style={{ width: `${COLUMN_WIDTH[6]}` }}
                    >
                      {purchase.purchase_item_allergy_master
                        .filter((item) => item.allergy_master.type_name === SPECIFIC_RAW_MATERIALS)
                        .map(
                          (item) =>
                            `${item.allergy_master.allergy_name} (${item.allergy_item_master.allergy_item_name})`
                        )
                        .join(', ')}
                    </div>
                    <div
                      className="div-td bg-yellow d-flex align-items-center justify-content-center"
                      style={{ width: `${COLUMN_WIDTH[7]}` }}
                    >
                      {purchase.purchase_item_allergy_master
                        .filter((item) => item.allergy_master.type_name === ITEMS_EQUIVALENT_TO_SPECIFIC_RAW_MATERIALS)
                        .map(
                          (item) =>
                            `${item.allergy_master.allergy_name} (${item.allergy_item_master.allergy_item_name})`
                        )
                        .join(', ')}
                    </div>
                    <div
                      className="div-td bg-yellow d-flex align-items-center justify-content-center"
                      style={{ width: `${COLUMN_WIDTH[8]}` }}
                    >
                      {purchase.applicant_name}
                    </div>
                    <div
                      className="div-td bg-yellow d-flex align-items-center justify-content-center"
                      style={{ width: `${COLUMN_WIDTH[9]}` }}
                    >
                      {purchase.applied_date}
                    </div>
                    <div
                      className="div-td bg-yellow d-flex align-items-center justify-content-center"
                      style={{ width: `${COLUMN_WIDTH[10]}` }}
                    >
                      {purchase.verifier_name}
                    </div>
                    <div
                      className="div-td bg-yellow d-flex align-items-center justify-content-center"
                      style={{ width: `${COLUMN_WIDTH[11]}` }}
                    >
                      {purchase.verify_date}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="py-1">
          <div className="anra-pagination d-inline-flex flex-row gap-1 align-items-center">
            <Button size="sm" variant="primary" className="btn--auto me-1" onClick={handlePrevPage2}>
              <TfiAngleLeft size={16} />
            </Button>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleEnterToPage2();
              }}
            >
              <Form.Control
                size="sm"
                type="text"
                ref={page2CurrentElement}
                defaultValue={pageRef.current}
                className="d-inline-block text-center"
                style={{ width: '5rem' }}
                disabled
              />
            </Form>
            <span>/{totalPage2Ref.current}ページ</span>
            <Button size="sm" variant="primary" className="btn--auto ms-1" onClick={handleNextPage2}>
              <TfiAngleRight size={16} />
            </Button>
          </div>
        </div>

        {/* <!-- Table all status--> */}

        <div className="sticky-table">
          <div className="div-table table-approved">
            <div className="div-thead div-tr">
              <div
                className="div-th d-flex align-items-center justify-content-center"
                style={{ width: `${COLUMN_WIDTH[0]}` }}
              >
                {t('CalorieAllergySetting.table.selection')}
              </div>
              <div
                className="div-th d-flex align-items-center justify-content-center"
                style={{ width: `${COLUMN_WIDTH[1]}` }}
              >
                {t('CalorieAllergySetting.table.status')}
              </div>
              <div
                className="div-th d-flex align-items-center justify-content-center"
                style={{ width: `${COLUMN_WIDTH[2]}` }}
              >
                {t('CalorieAllergySetting.table.sms_code')}
              </div>
              <div
                className="div-th d-flex align-items-center justify-content-center lh-1"
                style={{ width: `${COLUMN_WIDTH[3]}` }}
              >
                <span dangerouslySetInnerHTML={{ __html: t('CalorieAllergySetting.table.element_code') }} />
              </div>
              <div
                className="div-th d-flex align-items-center justify-content-center"
                style={{ width: `${COLUMN_WIDTH[4]}` }}
              >
                {t('CalorieAllergySetting.table.name')}
              </div>
              {nutritions &&
                nutritions.map((nutrition, idx) => {
                  return (
                    <div
                      className="div-th d-flex align-items-center justify-content-center lh-1"
                      style={{ width: `${COLUMN_WIDTH[5]}` }}
                      key={idx}
                    >
                      {nutrition.nutrition_name} <br></br> ({nutrition.nutrition_unit_name})
                    </div>
                  );
                })}
              <div
                className="div-th d-flex align-items-center justify-content-center"
                style={{ width: `${COLUMN_WIDTH[6]}` }}
              >
                {t('CalorieAllergySetting.table.specific_raw_materials')}
              </div>
              <div
                className="div-th d-flex align-items-center justify-content-center"
                style={{ width: `${COLUMN_WIDTH[7]}` }}
              >
                {t('CalorieAllergySetting.table.items_equivalent_to_specific_raw_materials')}
              </div>
              <div
                className="div-th d-flex align-items-center justify-content-center"
                style={{ width: `${COLUMN_WIDTH[8]}` }}
              >
                {t('CalorieAllergySetting.table.last_update_by')}
              </div>
              <div
                className="div-th d-flex align-items-center justify-content-center"
                style={{ width: `${COLUMN_WIDTH[9]}` }}
              >
                {t('CalorieAllergySetting.table.last_update_date')}
              </div>
              <div
                className="div-th d-flex align-items-center justify-content-center"
                style={{ width: `${COLUMN_WIDTH[10]}` }}
              >
                {t('CalorieAllergySetting.table.confirmed_by')}
              </div>
              <div
                className="div-th d-flex align-items-center justify-content-center"
                style={{ width: `${COLUMN_WIDTH[11]}` }}
              >
                {t('CalorieAllergySetting.table.confirmed_date')}
              </div>
            </div>

            {data2.length > 0 &&
              data2.map((purchase: PurchaseCalorieSetting, idx) => {
                return (
                  <div className="div-tr" key={idx}>
                    <div
                      className="div-td bg-yellow d-flex align-items-center justify-content-center"
                      style={{ width: `${COLUMN_WIDTH[0]}` }}
                    >
                      <Form.Check
                        className="anra-checkbox d-flex justify-content-center"
                        value={purchase.supplier_item_cd.toString()}
                        label=""
                        type="checkbox"
                        onChange={handleCheckboxChange2}
                        defaultChecked={false}
                        ref={(element: HTMLInputElement) => {
                          checkBox2Refs.current[idx] = element;
                        }}
                      />
                    </div>
                    <div
                      className="div-td bg-yellow d-flex align-items-center justify-content-center"
                      style={{ width: `${COLUMN_WIDTH[1]}` }}
                    >
                      {purchase.status}
                    </div>
                    <div
                      className="div-td bg-yellow d-flex align-items-center justify-content-center"
                      style={{ width: `${COLUMN_WIDTH[2]}` }}
                    >
                      {purchase.supplier_item_cd}
                    </div>
                    <div
                      className="div-td bg-yellow d-flex align-items-center justify-content-center"
                      style={{ width: `${COLUMN_WIDTH[3]}` }}
                    >
                      {purchase.process_item
                        ? purchase.process_item.process_item_cd
                        : purchase.purchase_item.purchase_item_cd}
                    </div>
                    <div
                      className="div-td bg-yellow d-flex align-items-center justify-content-start"
                      style={{ width: `${COLUMN_WIDTH[4]}` }}
                    >
                      &nbsp;
                      {purchase.process_item
                        ? purchase.process_item.process_item_name
                        : purchase.purchase_item.purchase_item_name}
                    </div>
                    {nutritions &&
                      nutritions.map((nutrition, idx) => {
                        const item = purchase.purchase_nutrition_master.find(
                          (item) => item.nutrition_cd === nutrition.nutrition_cd
                        );
                        return (
                          <div
                            className="div-td bg-yellow d-flex align-items-center justify-content-start"
                            style={{ width: `${COLUMN_WIDTH[5]}` }}
                            key={idx}
                          >
                            &nbsp;{item?.nutrition_amount !== undefined ? item.nutrition_amount : ''}
                          </div>
                        );
                      })}
                    <div
                      className="div-td bg-yellow d-flex align-items-center justify-content-center"
                      style={{ width: `${COLUMN_WIDTH[6]}` }}
                    >
                      {purchase.purchase_item_allergy_master
                        .filter((item) => item.allergy_master.type_name === SPECIFIC_RAW_MATERIALS)
                        .map(
                          (item) =>
                            `${item.allergy_master.allergy_name} (${item.allergy_item_master.allergy_item_name})`
                        )
                        .join(', ')}
                    </div>
                    <div
                      className="div-td bg-yellow d-flex align-items-center justify-content-center"
                      style={{ width: `${COLUMN_WIDTH[7]}` }}
                    >
                      {purchase.purchase_item_allergy_master
                        .filter((item) => item.allergy_master.type_name === ITEMS_EQUIVALENT_TO_SPECIFIC_RAW_MATERIALS)
                        .map(
                          (item) =>
                            `${item.allergy_master.allergy_name} (${item.allergy_item_master.allergy_item_name})`
                        )
                        .join(', ')}
                    </div>
                    <div
                      className="div-td bg-yellow d-flex align-items-center justify-content-center"
                      style={{ width: `${COLUMN_WIDTH[8]}` }}
                    >
                      {purchase.applicant_name}
                    </div>
                    <div
                      className="div-td bg-yellow d-flex align-items-center justify-content-center"
                      style={{ width: `${COLUMN_WIDTH[9]}` }}
                    >
                      {purchase.applied_date}
                    </div>
                    <div
                      className="div-td bg-yellow d-flex align-items-center justify-content-center"
                      style={{ width: `${COLUMN_WIDTH[10]}` }}
                    >
                      {purchase.verifier_name}
                    </div>
                    <div
                      className="div-td bg-yellow d-flex align-items-center justify-content-center"
                      style={{ width: `${COLUMN_WIDTH[11]}` }}
                    >
                      {purchase.verify_date}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <ConfirmationDialog
        show={showConfirmationDialog}
        onHide={() => setShowConfirmationDialog(false)}
        onConfirm={handleHiddenPurchasedProcessedItem}
        title={t('CalorieAllergySetting.DialogConfirm.title')}
        message={hiddenMessagesRef.current}
      />
    </>
  );
}

export default PurchaseProcessedCalorieAllergySettingList;
