export const approveGroupButtonTranslationJP = {
  button: {
    confirmation_request_btn: '確認依頼',
    approval_request_btn: '承認依頼',
    approval_btn: '承認',
    confirmation_cancel_btn: '差戻',
    turn_back_btn: '差戻',
    temporary_save_btn: '一時保存',
  },
};
